import Modules from './modules/Modules'
import { css, StyleSheet } from 'aphrodite'
import Licenses from './Licenses'
import Clients from './clients/Clients'
import Support from './support/Support'
import Analytics from './Analytics'
import Header from '../uiElements/Header'
import { useData } from '../store'
import UserProfiles from './userProfiles/UserProfiles'
import ContentManagement from './ContentManagement'
import EvaluationUI from './evaluation/EvaluationUI'
import Reports from './Reports'
import { useClientInfo } from '../auth/hooks/useUserInfo'

const Dashboard = () => {
	const { currentPage } = useData()
	const client = useClientInfo(
		JSON.parse(localStorage.getItem('userDetails')).user.clientID
	)
	localStorage.setItem('primaryColor', client.primaryColor)
	localStorage.setItem('secondaryColor', client.secondaryColor)
	// console.log(
	// 	'client in dashboard',
	// 	client,
	// 	client.primaryColor,
	// 	client.secondaryColor
	// )

	function displayTab() {
		switch (currentPage) {
			case 'modules':
				return <Modules />
			case 'license':
				return <Licenses />
			case 'clients':
				return <Clients />
			case 'support':
				return <Support />
			case 'analytics':
				return <Analytics />
			case 'user profiles':
				return <UserProfiles />
			case 'content management':
				return <ContentManagement />
			case 'evaluation':
				return <EvaluationUI />
			case 'reports':
				return <Reports />
		}
	}

	return (
		<div className={css(styles.container)}>
			<Header />
			<div> {displayTab()} </div>
		</div>
	)
}

export default Dashboard

const styles = StyleSheet.create({
	container: {
		// padding: '0 1rem'
	},
	header: {
		borderBottom: '2px solid rgba(219, 225, 232, 1)',
		display: 'flex',
		padding: '1rem 2rem 0',
	},
	metaContainer: {
		display: 'inline-block',
		display: 'flex',
		alignSelf: 'center',
		marginLeft: 'auto',
		gap: '1.5rem',
	},
})
