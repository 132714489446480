import axios from "axios"
import { BASE_URL } from "../constants"

export const getUserData = async () => {
  try{
    const response = await axios.get(`${BASE_URL}/auth/user/login`)
    // console.log(response)
    return response.data.user
  }catch(err){
    console.log(err)
  }
}