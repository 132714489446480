const UserAvatarIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 4C11.93 4 13.5 5.57 13.5 7.5C13.5 9.43 11.93 11 10 11C8.07 11 6.5 9.43 6.5 7.5C6.5 5.57 8.07 4 10 4ZM10 18C7.97 18 5.57 17.18 3.86 15.12C5.6116 13.7457 7.77362 12.9988 10 12.9988C12.2264 12.9988 14.3884 13.7457 16.14 15.12C14.43 17.18 12.03 18 10 18Z"
				fill="black"
			/>
		</svg>
	)
}

export default UserAvatarIcon
