import { css, StyleSheet } from 'aphrodite'
import EditIcon from '../svgs/EditIcon'

const EditButton = ({ clickHandler }) => {
	return (
		<button className={css(styles.wrapper)} onClick={clickHandler}>
			<EditIcon />
		</button>
	)
}

export default EditButton

const styles = StyleSheet.create({
	wrapper: {
		background: 'none',
		border: 0,
		':hover': {
			cursor: 'pointer',
		},
	},
})
