import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import { css, StyleSheet } from 'aphrodite'
import TertiaryList from '../../uiElements/TertiaryList'
import EditIcon from '../../svgs/EditIcon'
import { useData } from '../../store'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../constants'
import { useModuleAssignments } from '../../auth/hooks/moduleApiHooks'
import { useClientUsers } from '../../auth/hooks/useClientUsers'

const ClientInfo = ({}) => {
	const { activeClient, setActiveClient, setActiveClientSubPage } = useData()
	function navToMainClientPage() {
		setActiveClientSubPage('main')
	}
	// const [modules, setModules] = useState([])
	const [modules] = useModuleAssignments(activeClient._id)
	const admins = useClientUsers(activeClient._id, '?roles=admin')
	return (
		<div className={css(styles.container)}>
			<div className={css(styles.flexBlock)}>
				<BreadCrumb
					items={[
						{
							name: 'CLIENTS',
							clickHandler: navToMainClientPage,
						},
						{ name: activeClient.clientName },
					]}
				/>
				<Button>EDIT CLIENT INFO</Button>
			</div>
			<div className={css(styles.flexBlock, styles.listsWrapper)}>
				<TertiaryList
					title="MODULES"
					list={modules.map((mod) => mod.name)}
					customStyles={styles.singleList}
					sort
					edit
					options
					editClickHandler={() =>
						setActiveClientSubPage('module access')
					}
				/>
				<TertiaryList
					title="TICKETS"
					list={''}
					customStyles={styles.singleList}
					sort
				/>
				<TertiaryList
					title="ADMINS"
					list={admins.map((admin) => admin.userName)}
					customStyles={styles.singleList}
					sort
					edit
					editClickHandler={() =>
						setActiveClientSubPage('add admins')
					}
				/>
			</div>
			<div className={css(styles.flexBlock, styles.licensePayment)}>
				<section>
					<h3>
						LICENSE <EditIcon />
					</h3>
					<p>License Version, Status, Expiry</p>
				</section>
				<section>
					<h3>PAYMENT ORDERS</h3>
					<p>Paid,due or receipt view</p>
				</section>
			</div>
		</div>
	)
}

export default ClientInfo

const styles = StyleSheet.create({
	container: {
		paddingLeft: '3rem',
		paddingRight: '2rem',
	},
	flexBlock: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	listsWrapper: {
		justifyContent: 'flex-start',
		gap: '2rem',
	},
	singleList: {
		width: '17%',
	},
	licensePayment: {
		justifyContent: 'flex-start',
		gap: '5rem',
		marginTop: '2rem',
	},
})
