import { css, StyleSheet } from 'aphrodite'

const ListItem = ({ title, clickHandler, children }) => {
	return (
		<button className={css(styles.btn)} onClick={clickHandler}>
			<div className={css(styles.container)}>
				<h2 className={css(styles.title)}> {title} </h2>
				{children}
			</div>
		</button>
	)
}

export default ListItem

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		borderBottom: '2px solid rgba(219, 225, 232, 1)',
		padding: '0 2rem 0 3rem',
	},
	title: {
		fontSize: '1.25rem',
	},
	btn: {
		background: 'none',
		border: 0,
		display: 'block',
		width: '100%',
		':hover': {
			cursor: 'pointer',
		},
	},
})
