import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import {
	useGetAllModules,
	useModuleAssignments,
} from '../../auth/hooks/moduleApiHooks'
import { userUserInfo } from '../../auth/hooks/useUserInfo'
import { BASE_URL } from '../../constants'
import { dateFormater } from '../../helpers/date'
import { useData } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import { SecondaryListOnlyUI } from '../../uiElements/SecondaryListOnlyUI'

const ModuleAccess = () => {
	const { activeClient, setActiveClientSubPage } = useData()
	const [modules, refreshModules] = useModuleAssignments(activeClient._id)
	const allModules = useGetAllModules()
	const user = userUserInfo()
	const onAssignModule = (item, idx) => {
		console.log(item)
		const entry = { moduleId: item._id, clientID: activeClient._id }
		axios
			.post(`${BASE_URL}/api/v1/moduleassignment/`, entry)
			.then((res) => {
				console.log(res)
				refreshModules()
			})
			.catch(console.log)
	}
	const onUnassignModule = (item, idx) => {
		axios
			.delete(
				`${BASE_URL}/api/v1/moduleassignment/${activeClient._id}/${item._id}`
			)
			.then(refreshModules)
			.catch(console.log)
	}
	return (
		<div className={css(styles.container)}>
			<BreadCrumb
				items={[
					{
						name: 'CLIENTS',
						clickHandler: () => setActiveClientSubPage('main'),
					},
					{
						name: activeClient.clientName,
						clickHandler: () => setActiveClientSubPage('info'),
					},
					{ name: 'MODULE ACCESS' },
				]}
			/>
			<div className={css(styles.flexBlock)}>
				<SecondaryListOnlyUI
					title="MODULES ACCESSED"
					sort
					items={modules}
					displayKey="name"
					customListStyles={styles.list}
					onDelete={onUnassignModule}
				/>
				<SecondaryListOnlyUI
					title="ALL MODULES"
					sort
					onClick={onAssignModule}
					noDelete
					onTitle
					customListStyles={styles.list}
					items={allModules.filter(
						(mod) =>
							!modules.some(
								(moduleAvailable) =>
									moduleAvailable._id === mod._id
							)
					)}
					displayKey="name"
				/>
			</div>
		</div>
	)
}

export default ModuleAccess

const styles = StyleSheet.create({
	container: {
		paddingLeft: '3rem',
		paddingRight: '2rem',
	},
	flexBlock: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	list: {
		width: '46%',
		height: '60vh',
	},
})
