const Filter = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4H13V6H3V4ZM0 0H16V2H0V0ZM6 8H10V10H6V8Z"
        fill="#595959"
      />
    </svg>
  )
}

export default Filter
