import { useState } from 'react'
import ListHeader from '../uiElements/ListHeader'
import ListItem from '../uiElements/ListItem'
import uuid from 'react-uuid'

/* LICENSES DATA FOR TESTING */
const allLicenses = [
  {
    id: 347924,
    license: 1,
  },
  {
    id: 393749,
    license: 2,
  },
  {
    id: 948732,
    license: 3,
  },
  {
    id: 437293,
    license: 4,
  },
]

const Licenses = () => {
  const [licensesData, setLicensesData] = useState(allLicenses)
  return (
    <div>
      <ListHeader title="ACTIVE LICENSES" btnContent="CREATE NEW LICENSE" />
      {licensesData.map((license) => (
        <ListItem key={uuid()} title={`License ${license.license}`} />
      ))}
    </div>
  )
}

export default Licenses
