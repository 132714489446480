import Button from '../../uiElements/Button'
import { css, StyleSheet } from 'aphrodite'
import SearchBar from '../../uiElements/SearchBar'
import uuid from 'react-uuid'
import CloseIcon from '../../svgs/CloseIcon'
import Header from '../../uiElements/Header'
import BreadCrumb from '../../uiElements/BreadCrumb'
import TextInput from '../../uiElements/TextInput'
import Submit from '../../uiElements/Submit'

const NewModuleForm = () => {
	return (
		<>
			<Header />
			<BreadCrumb
				items={[
					{ name: 'MODULE LIST', clickHandler: '' },
					{ name: 'ADD MODULE' },
				]}
			/>
			<div className={css(styles.container)}>
				<form>
					<TextInput
						labelText="MODULE NAME"
						customInputStyles={styles.input}
					/>
					<TextInput
						labelText="CATEGORY"
						customInputStyles={styles.input}
					/>
					<TextInput
						labelText="GITHUB LINK?"
						customInputStyles={styles.input}
					/>
					<Submit
						value="SAVE AND UPDATE"
						customStyles={styles.saveBtn}
					/>
				</form>
				<div className={css(styles.clients)}>
					<h2>ADD CLIENTS</h2>
					<SearchBar customStyles={styles.searchBarStyles} />
					<div className={css(styles.wrapper)}>
						{Array(10)
							.fill('CLIENT NAME')
							.map((client) => {
								return (
									<div
										key={uuid()}
										className={css(styles.singleClient)}
									>
										<h3> {client} </h3>
										<CloseIcon />
									</div>
								)
							})}
					</div>
				</div>
			</div>
		</>
	)
}

export default NewModuleForm

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: '3rem',
		paddingRight: '4rem',
	},
	clients: {
		width: '40%',
		overflow: 'hidden',
		border: '2px solid rgba(219, 225, 232, 1)',
		padding: '1rem',
		paddingBottom: 0,
	},
	wrapper: {
		height: 250,
		overflowY: 'scroll',
		borderTop: '2px solid rgba(219, 225, 232, 1)',
		borderRight: '2px solid rgba(219, 225, 232, 1)',
		marginLeft: '-1rem',
		marginTop: '1rem',
		'::-webkit-scrollbar': {
			width: '.7rem',
			background: 'rgba(140, 140, 140, 1)',
			borderRadius: 5,
			position: 'absolute',
			display: 'block',
		},
		'::-webkit-scrollbar-thumb': {
			background: 'rgba(35, 34, 34, 1)',
			borderRadius: 5,
		},
	},
	singleClient: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: '2px solid rgba(219, 225, 232, 1)',
		paddingLeft: '1rem',
		paddingRight: '1.5rem',
		':last-child': {
			borderBottom: 0,
		},
	},
	input: {
		width: 300,
	},
	saveBtn: {
		display: 'block',
		marginTop: '1rem',
		position: 'fixed',
		bottom: 50,
		right: 50,
	},
	searchBarStyles: {
		width: '70%',
	},
})
