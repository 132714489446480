import { css, StyleSheet } from 'aphrodite'

const Button = ({ customStyles, clickHandler, children }) => {
	return (
		<button
			className={css(styles.btn, customStyles)}
			onClick={(e) => {
				clickHandler && clickHandler(e)}}
		>
			{children}
		</button>
	)
}

export default Button

const styles = StyleSheet.create({
	btn: {
		border: 0,
		background: 'rgba(217, 217, 217, 1)',
		borderRadius: '.5rem',
		padding: '.5rem 1.5rem',
		fontWeight: 500,
		fontSize: '.7rem',
		// position:'relative',
		':hover': {
			cursor: 'pointer',
		},
	},
})
