import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../constants";

export const useModuleAssignments = (clientID) => {
  const [modules, setModules] = useState([]);

  const fetch = async () => {
    const modules = await axios.get(
      `${BASE_URL}/api/v1/moduleassignment?clientID=${clientID}`
    );
    let modulesResolved = [];
    for (module of modules.data) {
      const moduleId = module.moduleId;
      // console.log(moduleId);
      const response = await axios.get(
        `${BASE_URL}/api/v1/module?_id=${moduleId}`
      );
      // console.log(response.data);
      modulesResolved.push(response.data[0]);
    }
    setModules(modulesResolved);
  };
  useEffect(() => {
    fetch();
  }, [clientID]);

  return [modules, fetch];
};

export const useGetAllModules = () => {
  const [modules, setModules] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/module`)
      .then((res) => setModules(res.data))
      .catch((err) => console.log(err));
  }, []);
  return modules;
};

export const useGetModulesByClientID = (clientID) => {
  const [modules, setModules] = useState([])
  const fetch = () => {
    axios
      .get(`${BASE_URL}/api/v1/moduleassignment/byclient/${clientID}`)
      .then(res => setModules(res.data))
      .catch(console.log)
  }
  useEffect(()=>{
    if(!clientID)return
    fetch()
  }, [clientID])
  return [modules, fetch]
}

export const useGetModulesByUserId = (userId) => {
  const [modules, setModules] = useState([])
  const fetch = () => {
    axios
      .get(`${BASE_URL}/api/v1/moduleassignment/byuser/${userId}`)
      .then(res => setModules(res.data))
      .catch(console.log)
  }
  useEffect(()=>{
    if(!userId)return
    fetch()
  }, [userId])
  return [ modules, fetch]
}
