import { css, StyleSheet } from 'aphrodite'
import Button from './Button'
import SearchBar from './SearchBar'
import SortButton from './SortButton'

const ListHeader = ({ title, btnContent, clickHandler }) => {
	return (
		<header className={css(styles.header)}>
			<h1 className={css(styles.title)}>{title} </h1>
			<div className={css(styles.headerRightPane)}>
				<SortButton />
				<SearchBar customStyles={styles.searchBarStyles} />
				{btnContent && <Button clickHandler={clickHandler}>{btnContent} </Button>}
			</div>
		</header>
	)
}

export default ListHeader

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		padding: '0 2rem 0 3rem',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: '2px solid rgba(219, 225, 232, 1)',
	},
	title: {
		fontSize: '2rem',
	},
	headerRightPane: {
		display: 'flex',
		gap: '.7rem',
	},
	searchBarStyles: {
		width: '18vw',
	},
})
