import axios from 'axios'
import { useEffect } from 'react'
import { Routes, Route } from 'react-router'
import { BrowserRouter as Router, Navigate } from 'react-router-dom'
import { isTokenValid } from './auth/hooks/authenticate'
import LoginForm from './auth/LoginForm'
import ProtectedRoutes from './auth/ProtectedRoutes'
import RegisterForm from './auth/RegisterForm'
import Dashboard from './pages/Dashboard'
import Evaluations from './pages/Evaluations'
import NewModuleForm from './pages/modules/NewModuleForm'
import { ResetPassword } from './pages/userProfiles/ResetPassword'
import { MessagePopup } from './uiElements/MessagePopup'
import { Modal } from './uiElements/Modal'

function App() {
	useEffect(() => {
		if (localStorage.userToken) {
			axios.defaults.headers.common.authorization = `Bearer ${localStorage.userToken}`
		}
	}, [])
	return (
		<div className="App">
			<Modal />
			<MessagePopup />
			<Router>
				<Routes>
					<Route path='/reset-password' element={<ResetPassword />} />
					<Route element={<ProtectedRoutes />}>
						<Route path="/" element={<Dashboard />} />
						<Route path="/new-module" element={<NewModuleForm />} />
						<Route path="/evaluations" element={<Evaluations />} />
					</Route>
					<Route
						path="/register"
						element={
							isTokenValid('userToken', 'userTimestamp') ? (
								<Navigate to="/" />
							) : (
								<RegisterForm />
							)
						}
					/>
					<Route
						path="/login"
						element={
							isTokenValid('userToken', 'userTimestamp') ? (
								<Navigate to="/" />
							) : (
								<LoginForm />
							)
						}
					/>
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</Router>
		</div>
	)
}

export default App
