const RightArrowIcon = () => {
	return (
		<svg
			width="11"
			height="18"
			viewBox="0 0 11 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.748378 0.914932C0.195135 1.46817 0.195135 2.36516 0.748378 2.9184L6.82998 9L0.748378 15.0816C0.195135 15.6348 0.195135 16.5318 0.748378 17.0851C1.30162 17.6383 2.1986 17.6383 2.75185 17.0851L10.8369 9L2.75185 0.914932C2.1986 0.361689 1.30162 0.361689 0.748378 0.914932Z"
				fill="#12131A"
			/>
		</svg>
	)
}

export default RightArrowIcon
