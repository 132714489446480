import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { BASE_URL, SUPER_ADMIN_TOKEN } from '../constants'
import CloseIcon from '../svgs/CloseIcon'
import SearchBar from './SearchBar'
import SortButton from './SortButton'

export const SecondaryListOnlyUI = ({
  title,
  sort,
  customListStyles,
  items = [],
  displayKey,
  onClick,
  onDelete,
  noDelete,
  customListHeaderStyles,
  customListBodyStyles,
  customSingleItemStyles,
  hideSearch,
  showDeviceId,
  onClickSort,
  customActiveButtonStyle,
}) => {
  return (
    <section className={css(styles.container, customListStyles)}>
      <div className={css(styles.flexBlock, customListHeaderStyles)}>
        <h2>{title}</h2>
        {!hideSearch && (
          <>
            {sort ? (
              <div className={css(styles.flexBlock)}>
                {sort && <SortButton clickHandler={()=>onClickSort && onClickSort()} />}
                <SearchBar customStyles={styles.sortSearchBarStyles} />
              </div>
            ) : (
              <SearchBar customStyles={styles.searchBarStyles} />
            )}
          </>
        )}
      </div>
      <div className={css(styles.list, customListBodyStyles)}>
        {/* {Array(10)
					.fill('CLIENT NAME') */}
        {items.map((listItem, i) => {
          return (
            <div
              onClick={() => {
                onClick && onClick(listItem, i)
              }}
              key={listItem.id || listItem._id}
              className={css(
                styles.singleItem,
                styles.btnStyle,
                customSingleItemStyles,
              )}
            >
              <h3>{listItem[displayKey]}</h3>
              <div
                // onClick={()=> onClick && onClick(listItem, i)}
                className={css(styles.flexBlock, styles.flexGap)}
              >
                {listItem.deviceId && (
                  <p>DEVICE ID: {listItem.deviceId} </p>
                )}
                {listItem.score && (
                  <p>{listItem.score} </p>
                )}
                {!noDelete && (
                  <CloseIcon
                    onClick={() => onDelete && onDelete(listItem, i)}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

const styles = StyleSheet.create({
  btnStyle: {
    color: 'black',
    cursor: 'pointer',
    ':hover': {
      background: 'red',
      border: '2px solid red',
      color: '#fff',
      ':nth-child(n) button': {
        color: '#fff',
      },
      ':nth-child(n) svg': {
        fill: '#fff',
        filter: 'invert(100%)',
      },
    },
  },
  container: {
    overflow: 'hidden',
    border: '2px solid rgba(219, 225, 232, 1)',
    padding: '1rem',
    paddingBottom: 0,
    width: '40%',
    // height: '50vh',
    height: 400,
    maxHeight: 450,
  },
  flexBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1rem 0px',
  },
  flexGap: {
    gap: '1.5rem',
  },
  list: {
    // height: 'calc(80%)',
    height: '75%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderTop: '2px solid rgba(219, 225, 232, 1)',
    borderRight: '2px solid rgba(219, 225, 232, 1)',
    marginLeft: '-1rem',
    marginTop: '1rem',
    paddingLeft: '1rem',
    '::-webkit-scrollbar': {
      width: '.7rem',
      background: 'rgba(140, 140, 140, 1)',
      borderRadius: 5,
      position: 'absolute',
      display: 'block',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(35, 34, 34, 1)',
      borderRadius: 5,
    },
  },
  singleItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '2px solid rgba(219, 225, 232, 1)',
    paddingLeft: '1rem',
    paddingRight: '1.5rem',
    ':last-child': {
      borderBottom: 0,
    },
  },
  searchBarStyles: {
    width: '70%',
  },
  sortSearchBarStyles: {
    width: '75%',
    marginLeft: 'auto',
    alignSelf: 'center',
  },
})
