import axios from 'axios'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { BASE_URL } from '../../constants'
import { css, StyleSheet } from 'aphrodite'
import ListItem from '../../uiElements/ListItem'
import WrenchIcon from '../../svgs/WrenchIcon'
import EditIcon from '../../svgs/EditIcon'
import DeleteIcon from '../../svgs/DeleteIcon'
import ListHeader from '../../uiElements/ListHeader'
import { useClientInfo, userUserInfo } from '../../auth/hooks/useUserInfo'
import { useModuleAssignments } from '../../auth/hooks/moduleApiHooks'

const Modules = () => {
	const [modulesData, setModulesData] = useState([])
	const user = userUserInfo()
	const [modules] = useModuleAssignments(user.clientID)
	
	// const client = useClientInfo(user.clientID)
	useEffect(() => {
		// const fetchModules = async () => {
		// 	try {
		// 		const modules = await axios.get(
		// 			BASE_URL + `/api/v1/module?${}`
		// 		)
		// 		console.log('modules', modules.data)
		// 		setModulesData(modules.data)
		// 	} catch (err) {
		// 		console.log('fetching modules failed', err)
		// 	}
		// }
		// fetchModules()
	}, [])
	return (
		<section className={css(styles.container)}>
			<ListHeader title="MODULE LIST" btnContent={user.roles==='SuperAdmin'?"ADD MODULE":''} />
			{modules.map((module) => (
				<ListItem key={module._id} title={`Module ${module.name}`}>
					<WrenchIcon wrenchStyle={css(styles.wrench)} />
					<div className={css(styles.mods)}>
						<EditIcon />
						{user.roles==='SuperAdmin' && <DeleteIcon />}
					</div>
				</ListItem>
			))}
		</section>
	)
}

export default Modules

const styles = StyleSheet.create({
	container: {
		// padding: '0 2rem 0 3rem',
	},
	header: {
		display: 'flex',
		padding: '0 2rem 0 3rem',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: '2px solid rgba(219, 225, 232, 1)',
	},
	title: {
		fontSize: '2rem',
	},
	headerRightPane: {
		display: 'flex',
		gap: '.7rem',
	},
	wrench: {
		marginRight: 'auto',
		marginLeft: '2rem',
		alignSelf: 'center',
	},
	mods: {
		display: 'flex',
		alignItems: 'center',
		gap: '2rem',
	},
})
