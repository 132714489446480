import { css, StyleSheet } from "aphrodite";
import React, { useState } from "react";
import SearchBar from "../SearchBar";
import SortButton from "../SortButton";

let color = "red";
export const SORTORDER = {
  ascs: 1,
  decs: 0
}
export const ListContainer = ({ children, onSort }) => {
  const [sortOrder, setSortOrder] = useState(SORTORDER.desc);
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.titleContainer)}>
        <h2 className={css(styles.title)}>TRAINEE</h2>
        <span className={css(styles.buttons)}>
          <SortButton
            clickHandler={() => {
              onSort(sortOrder);
              setSortOrder(!sortOrder);
            }}
          />
          <SearchBar />
        </span>
      </div>
      {children}
      {/* <h3>asdasd</h3> */}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    border: `${color} 3px solid`,
    margin: 0,
    padding: 0,
    borderRadius: "10px",
    width: 690,
    height: 500
  },
  titleContainer: {
    height: "100px",
    display: "flex",
    background: color,
    alignItems: "center",
    paddingLeft: 10,
    justifyContent: "space-between",
    // borderRadius: '5px',
    outline: `0.1px ${color} solid`,
  },
  title: {
    // margin:0,
    // width: '100%',
    // padding: '15px 60px 0px 60px',
    // fontSize: '',
    // background: color
  },
  buttons: {
    display: "flex",
    width: 250,
    justifyContent: "space-between",
    marginRight: "25px",
  },
});
