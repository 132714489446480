import { css, StyleSheet } from 'aphrodite'

const HeaderButton = ({ clickHandler, active, children }) => {
	return (
		<button
			onClick={clickHandler}
			className={css(styles.btn, active && styles.activebtn)}
		>
			{children}
		</button>
	)
}

export default HeaderButton

const styles = StyleSheet.create({
	btn: {
		background: 'none',
		border: 0,
		fontWeight: 600,
		padding: '1rem',
		marginBottom: 5,
		transition: 'box-shadow .3s',
		':hover': {
			cursor: 'pointer',
			boxShadow: '0px 5px 0 rgba(140, 140, 140, 1)',
		},
		':focus': {
			outline: 'none',
			boxShadow: '0px 5px 0 rgba(140, 140, 140, 1)',
		},
	},
	activebtn: {
		boxShadow: '0px 5px 0 rgba(140, 140, 140, 1)',
	},
})
