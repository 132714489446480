import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import SearchIcon from '../svgs/SearchIcon'

const AdvancedMultiSelect = ({
	label,
	isDropDownOpen,
	setIsDropDownOpen,
	items,
	checkBoxChangeHandler,
	checked,
	subLabelProp,
	subItemsProp,
	selectAll,
	reset,
	searchChangeHandler,
}) => {
	return (
		<div className={css(styles.selectContainer)}>
			<button
				className={css(styles.selectButton)}
				onClick={() => setIsDropDownOpen(!isDropDownOpen)}
			>
				{label}
				<i
					className={css(
						styles.arrow,
						isDropDownOpen ? styles.upArrow : styles.downArrow
					)}
				></i>
			</button>
			{isDropDownOpen && (
				<div className={css(styles.selectOptions)}>
					<div className={css(styles.header)}>
						<button
							className={css(styles.headerBtn)}
							onClick={selectAll}
						>
							Select All
						</button>
						<button
							className={css(styles.headerBtn)}
							onClick={reset}
						>
							Reset
						</button>
						<div className={css(styles.searchBarContainer)}>
							<SearchIcon />
							<input
								type="search"
								className={css(styles.searchBar)}
								onChange={searchChangeHandler}
							/>
						</div>
					</div>
					{items.map((item) => (
						<>
							<p className={css(styles.subLabel)}>
								{item[subLabelProp]}
							</p>
							{item[subItemsProp].map((subItem) => (
								<label
									key={uuid()}
									className={css(styles.optionLabel)}
								>
									{subItem}
									<input
										type="checkbox"
										value={subItem}
										className={css(styles.checkbox)}
										onChange={checkBoxChangeHandler}
										checked={checked(subItem)}
									/>
								</label>
							))}
						</>
					))}
				</div>
			)}
		</div>
	)
}

export default AdvancedMultiSelect

const styles = StyleSheet.create({
	selectContainer: {
		width: '15%',
		position: 'relative',
	},
	selectButton: {
		background: 'none',
		border: '1px solid #000000',
		borderRadius: '.2rem',
		width: '100%',
		padding: '0.3rem .5rem',
		fontSize: '0.8rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		boxSizing: 'border-box',
		':nth-child(n)::after': {
			content: 'a',
		},
	},
	arrow: {
		border: 'solid black',
		borderWidth: '0 3px 3px 0',
		display: 'inline-block',
		padding: 3,
		transition: 'all .3s',
	},
	downArrow: {
		transform: 'rotate(45deg)',
		'-webkit-transform': 'rotate(45deg)',
	},
	upArrow: {
		transform: 'rotate(-135deg)',
		'-webkit-transform': 'rotate(-135deg)',
	},
	selectOptions: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: '.8rem',
		border: '1px solid',
		position: 'absolute',
		background: '#fff',
		width: '100%',
		boxSizing: 'border-box',
		maxHeight: '10rem',
		overflow: 'auto',
		width: 300,
		'::-webkit-scrollbar': {
			width: '.35rem',
			background: '#ededed',
		},
		'::-webkit-scrollbar-thumb': {
			background: '#000',
			borderRadius: '2.4vh',
		},
	},
	optionLabel: {
		padding: '0.5rem 0.8rem',
		':hover': {
			background: '#0075ff',
			color: '#fff',
		},
	},
	checkbox: {
		float: 'right',
		clear: 'both',
		filter: 'hue-rotate(130deg)',
	},
	subLabel: {
		padding: '0.5rem',
		margin: 0,
		fontWeight: '900',
	},
	header: {
		display: 'flex',
		gap: '.3rem',
		padding: '.3rem',
		border: '1px solid rgb(217, 217, 217)',
	},
	headerBtn: {
		background: 'none',
		border: 0,
		outline: 0,
		padding: 0,
		fontSize: '.7rem',
		fontWeight: 'bold',
		textDecoration: 'underline',
		':hover': {
			cursor: 'pointer',
		},
	},
	searchBarContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '.3rem',
		background: 'rgb(217, 217, 217)',
		flex: 1,
		padding: '0.1rem 0.5rem',
		borderRadius: 3,
		':nth-child(n) svg': {
			width: 10,
		},
	},
	searchBar: {
		flex: 1,
		width: '100%',
		border: 0,
		background: 'rgb(217, 217, 217)',
		outline: 0,
	},
})
