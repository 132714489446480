const OptionsIcon = () => {
	return (
		<svg
			width="6"
			height="18"
			viewBox="0 0 6 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.02279 2.05478C5.02288 2.32051 4.97062 2.58364 4.86901 2.82917C4.76741 3.0747 4.61843 3.29781 4.4306 3.48577C4.24276 3.67372 4.01975 3.82284 3.77428 3.92461C3.52882 4.02637 3.26571 4.0788 2.99999 4.07888C2.73427 4.07897 2.47113 4.02671 2.2256 3.92511C1.98007 3.8235 1.75696 3.67452 1.56901 3.48669C1.38105 3.29885 1.23193 3.07584 1.13017 2.83037C1.0284 2.58491 0.975977 2.32181 0.975891 2.05608C0.975719 1.51943 1.18874 1.00469 1.56809 0.625097C1.94744 0.245505 2.46204 0.0321549 2.99869 0.0319825C3.53534 0.0318101 4.05008 0.244829 4.42968 0.624178C4.80927 1.00353 5.02262 1.51813 5.02279 2.05478V2.05478Z"
				fill="black"
			/>
			<path
				d="M2.99997 11.0222C4.11713 11.0222 5.02277 10.1165 5.02277 8.99936C5.02277 7.8822 4.11713 6.97656 2.99997 6.97656C1.88281 6.97656 0.977173 7.8822 0.977173 8.99936C0.977173 10.1165 1.88281 11.0222 2.99997 11.0222Z"
				fill="black"
			/>
			<path
				d="M2.99997 17.968C4.11713 17.968 5.02277 17.0623 5.02277 15.9452C5.02277 14.828 4.11713 13.9224 2.99997 13.9224C1.88281 13.9224 0.977173 14.828 0.977173 15.9452C0.977173 17.0623 1.88281 17.968 2.99997 17.968Z"
				fill="black"
			/>
		</svg>
	)
}

export default OptionsIcon
