import React from 'react'
import { StyleSheet, css } from 'aphrodite'
import { useStatusMessage } from '../store'

export const MessagePopup = () => {
  const {message} = useStatusMessage()
  return message?(
    <div className={css(styles.container)}>{message}</div>
  ):null
}

const styles = StyleSheet.create({
  container:{
    position:'absolute',
    left:'50%',
    top:'1%',
    transform: 'translateX(-50%)',
    backgroundColor: '#ADD8E6',
    textAlign: 'center',
    padding:'1%',
    borderRadius:'4px'
  }
})