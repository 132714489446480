const DeleteIcon = (props) => {
	return (
		<svg
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M18.9375 4.5H15.6875V2.46875C15.6875 1.57246 14.9588 0.84375 14.0625 0.84375H5.9375C5.04121 0.84375 4.3125 1.57246 4.3125 2.46875V4.5H1.0625C0.613086 4.5 0.25 4.86309 0.25 5.3125V6.125C0.25 6.23672 0.341406 6.32812 0.453125 6.32812H1.98672L2.61387 19.6074C2.65449 20.4732 3.37051 21.1562 4.23633 21.1562H15.7637C16.632 21.1562 17.3455 20.4758 17.3861 19.6074L18.0133 6.32812H19.5469C19.6586 6.32812 19.75 6.23672 19.75 6.125V5.3125C19.75 4.86309 19.3869 4.5 18.9375 4.5ZM13.8594 4.5H6.14062V2.67188H13.8594V4.5Z"
				fill="black"
			/>
		</svg>
	)
}

export default DeleteIcon
