import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import uuid from 'react-uuid'

const BasicMultiSelect = ({
	label,
	isDropDownOpen,
	setIsDropDownOpen,
	items,
	changeHandler,
	checked,
}) => {
	return (
		<div className={css(styles.selectContainer)}>
			<button
				className={css(styles.selectButton)}
				onClick={() => setIsDropDownOpen(!isDropDownOpen)}
			>
				{label}
				<i
					className={css(
						styles.arrow,
						isDropDownOpen ? styles.upArrow : styles.downArrow
					)}
				></i>
			</button>
			{isDropDownOpen && (
				<div className={css(styles.selectOptions)}>
					{items.map((item) => (
						<label key={uuid()} className={css(styles.optionLabel)}>
							{item}
							<input
								type="checkbox"
								value={item}
								className={css(styles.checkbox)}
								onChange={changeHandler}
								checked={checked(item)}
							/>
						</label>
					))}
				</div>
			)}
		</div>
	)
}

export default BasicMultiSelect

const styles = StyleSheet.create({
	selectContainer: {
		width: '15%',
		position: 'relative',
	},
	selectButton: {
		background: 'none',
		border: '1px solid #000000',
		borderRadius: '.2rem',
		width: '100%',
		padding: '0.3rem .5rem',
		fontSize: '0.8rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		boxSizing: 'border-box',
		':nth-child(n)::after': {
			content: 'a',
		},
	},
	selectOptions: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: '.8rem',
		border: '1px solid',
		position: 'absolute',
		background: '#fff',
		width: '100%',
		boxSizing: 'border-box',
	},
	checkbox: {
		float: 'right',
		clear: 'both',
		filter: 'hue-rotate(130deg)',
	},
	arrow: {
		border: 'solid black',
		borderWidth: '0 3px 3px 0',
		display: 'inline-block',
		padding: 3,
		transition: 'all .3s',
	},
	downArrow: {
		transform: 'rotate(45deg)',
		'-webkit-transform': 'rotate(45deg)',
	},
	upArrow: {
		transform: 'rotate(-135deg)',
		'-webkit-transform': 'rotate(-135deg)',
	},
	optionLabel: {
		padding: '.5rem',
		':hover': {
			background: '#0075ff',
			color: '#fff',
		},
	},
})
