const GearIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.82 20H8.18001C7.95194 20 7.73071 19.9221 7.55301 19.7792C7.37531 19.6362 7.25181 19.4368 7.20301 19.214L6.79601 17.33C6.25306 17.0921 5.73824 16.7946 5.26101 16.443L3.42401 17.028C3.20657 17.0973 2.97196 17.0902 2.75913 17.0078C2.5463 16.9254 2.36806 16.7727 2.25401 16.575L0.430006 13.424C0.317152 13.2261 0.274789 12.9958 0.309849 12.7708C0.344909 12.5457 0.455315 12.3392 0.623006 12.185L2.04801 10.885C1.9832 10.2961 1.9832 9.70189 2.04801 9.113L0.623006 7.816C0.455078 7.66177 0.344521 7.45507 0.309455 7.22978C0.27439 7.00449 0.316896 6.77397 0.430006 6.576L2.25001 3.423C2.36406 3.22532 2.5423 3.07259 2.75513 2.99019C2.96796 2.90778 3.20257 2.90066 3.42001 2.97L5.25701 3.555C5.50101 3.375 5.75501 3.207 6.01701 3.055C6.27001 2.913 6.53001 2.784 6.79601 2.669L7.20401 0.787C7.25258 0.564198 7.37584 0.364688 7.55335 0.221549C7.73087 0.0784098 7.95197 0.000239966 8.18001 0H11.82C12.048 0.000239966 12.2691 0.0784098 12.4467 0.221549C12.6242 0.364688 12.7474 0.564198 12.796 0.787L13.208 2.67C13.7503 2.90927 14.2649 3.20668 14.743 3.557L16.581 2.972C16.7983 2.90292 17.0327 2.91017 17.2453 2.99256C17.4579 3.07495 17.636 3.22753 17.75 3.425L19.57 6.578C19.802 6.985 19.722 7.5 19.377 7.817L17.952 9.117C18.0168 9.70589 18.0168 10.3001 17.952 10.889L19.377 12.189C19.722 12.507 19.802 13.021 19.57 13.428L17.75 16.581C17.636 16.7787 17.4577 16.9314 17.2449 17.0138C17.0321 17.0962 16.7974 17.1033 16.58 17.034L14.743 16.449C14.2661 16.8003 13.7516 17.0975 13.209 17.335L12.796 19.214C12.7472 19.4366 12.6239 19.6359 12.4464 19.7788C12.2689 19.9218 12.0479 19.9998 11.82 20ZM9.99601 6C8.93514 6 7.91772 6.42143 7.16758 7.17157C6.41743 7.92172 5.99601 8.93913 5.99601 10C5.99601 11.0609 6.41743 12.0783 7.16758 12.8284C7.91772 13.5786 8.93514 14 9.99601 14C11.0569 14 12.0743 13.5786 12.8244 12.8284C13.5746 12.0783 13.996 11.0609 13.996 10C13.996 8.93913 13.5746 7.92172 12.8244 7.17157C12.0743 6.42143 11.0569 6 9.99601 6Z"
				fill="black"
			/>
		</svg>
	)
}

export default GearIcon
