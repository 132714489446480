import axios from 'axios'

const useHandleFormSubmit = (
	INITIAL_FORM_DATA,
	formDetails,
	setFormDetails,
	formData,
	formLink
) => {
	// const [formDetails, setFormDetails] = useState(INITIAL_FORM_DATA)
	const handleInput = (input) => {
		setFormDetails((prev) => {
			const newObj = { ...prev }
			newObj[input.target.name] = input.target.value
			return newObj
		})
		console.log('formDetails', formDetails)
	}

	const checkFormData = () => {
		return Object.keys(INITIAL_FORM_DATA).every(
			(data) => formDetails[data] !== ''
		)
	}

	const onFormSubmit = async () => {
		const config = { headers: { 'Content-type': 'application/json' } }
		// const evaluationData = {
		// 	data: evaluationDetails.data,
		// 	score: evaluationDetails.score,
		// }

		// const evaluationLink = `${BASE_URL}/api/v1/evaluation/?stepId=${Number(
		// 	evaluationDetails.stepId
		// )}&module=${Number(evaluationDetails.module)}`

		// axios.defaults.headers.common.authorization = `Bearer ${localStorage.getItem(
		// 	'userToken'
		// )}`
		const response = await axios.post(formLink, formData, config)
		const data = response.data
		console.log('response data\n', response, '\n', data)
	}

	const handleFormSubmitAsync = async (e) => {
		e.preventDefault()
		console.log('e', e)
		if (checkFormData()) {
			try {
				await onFormSubmit()
			} catch (error) {
				console.log(error)
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log('error in response', error.response)
					alert(error.message)
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log('error in request', error.request)
					alert(error.request)
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('error in some other place')
				}
			}
		} else {
			console.log('invalid formDetails', formDetails)
			alert('Please fill valid details')
		}
	}

	return { handleInput, handleFormSubmitAsync }
}

export default useHandleFormSubmit
