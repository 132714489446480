import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { BASE_URL } from '../../constants'
import { useStatusMessage } from '../../store'
import Submit from '../../uiElements/Submit'
import TextInput from '../../uiElements/TextInput'

export const ResetPassword = () => {
  const location = useLocation()
  const [form, setForm] = useState({password: '', newPassword: ''})
  const [userName, clientName, userId] = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return [params.get('userName'), params.get('clientName'), params.get('userId')]
  }, [location.search])
  const {setMessage} = useStatusMessage()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    // location.
    // axios.headers.def
  }, [])
  const handleSubmit = async () => {
    try{
      const user = await axios.post(
        `${BASE_URL}/auth/user/login`,
        {
          userName: userName,
          clientName: clientName,
          password: form.password
        }
      )
      const {token} = user.data
      console.log(token)
			axios.defaults.headers.common.authorization = `Bearer ${token}`
      const editPassword = await axios.put(
        `${BASE_URL}/auth/user/editUser/${userId}`,{password: form.newPassword}
      )
      console.log(editPassword)
      setMessage('Password reset successfully')
    }catch(err){
      console.log(err)
      setMessage('Something went wrong: ' + err.response.data.message || err.message)
    }
  }
  return (
    <div style={{
      alignContent: 'center',
      textAlign: 'center',
      width:'100vw',
      height:'100vh',
      // backgroundColor:'red'
    }}>
      <h2 style={{textAlign:'center'}}>Reset password for user: {userName} of client: {clientName} </h2>
      <div style={{position:'absolute',left:'50vw',top:'50vh', transform:'translate(-50%,-50%)'}}>
        <TextInput
          labelText={'Old password'}
          value={form.password}
          onChange={(e) => setForm({...form, password: e.target.value})}
        />
        <TextInput
          labelText={'New password'}
          onChange={(e) => setForm((state) => ({...state, newPassword: e.target.value}))}
          value={form.newPassword}
        />
        <Submit onSubmit={handleSubmit}/>
        {/* <TextInput
          labelText={'Confirm new password'}
        /> */}

      </div>
    </div>
  )
}
