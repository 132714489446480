const SortIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 26 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M25.7968 11.9453L22.2577 0.878918C22.2347 0.804868 22.1885 0.74019 22.1259 0.69446C22.0633 0.64873 21.9876 0.624378 21.9101 0.625012H18.8866C18.7265 0.625012 18.5897 0.726575 18.539 0.878918L14.9726 11.9453C14.9608 11.9805 14.953 12.0195 14.953 12.0586C14.953 12.2578 15.1171 12.4219 15.3163 12.4219H17.5194C17.6835 12.4219 17.8241 12.3125 17.871 12.1563L18.5546 9.75001H22.0312L22.7069 12.1524C22.7499 12.3086 22.8944 12.418 23.0585 12.418H25.4491C25.4882 12.418 25.5233 12.4141 25.5585 12.4024C25.6522 12.3711 25.7265 12.3086 25.7733 12.2227C25.8163 12.1367 25.8241 12.0391 25.7968 11.9453ZM18.9101 7.71486L20.1913 3.14845H20.4374L21.6913 7.71486H18.9101ZM24.5155 26.9688H19.453V26.9531L24.6327 19.5742C24.6757 19.5117 24.6991 19.4414 24.6991 19.3633V17.9414C24.6991 17.7422 24.5351 17.5781 24.3358 17.5781H16.3671C16.1679 17.5781 16.0038 17.7422 16.0038 17.9414V19.6211C16.0038 19.8203 16.1679 19.9844 16.3671 19.9844H21.1562V20L15.9569 27.3789C15.9135 27.4407 15.8903 27.5144 15.8905 27.5899V29.0117C15.8905 29.2109 16.0546 29.375 16.2538 29.375H24.5116C24.7108 29.375 24.8749 29.2109 24.8749 29.0117V27.332C24.8754 27.2845 24.8665 27.2374 24.8487 27.1933C24.8308 27.1493 24.8045 27.1092 24.771 27.0754C24.7376 27.0416 24.6978 27.0148 24.654 26.9965C24.6101 26.9782 24.5631 26.9688 24.5155 26.9688ZM9.2499 22.4219H6.28115V1.71876C6.28115 1.54689 6.14053 1.40626 5.96865 1.40626H3.78115C3.60928 1.40626 3.46865 1.54689 3.46865 1.71876V22.4219H0.499902C0.238184 22.4219 0.089746 22.7266 0.253808 22.9297L4.62881 28.4727C4.65804 28.51 4.69539 28.5402 4.73803 28.561C4.78067 28.5818 4.82747 28.5926 4.8749 28.5926C4.92233 28.5926 4.96914 28.5818 5.01178 28.561C5.05442 28.5402 5.09176 28.51 5.121 28.4727L9.496 22.9297C9.65615 22.7266 9.51162 22.4219 9.2499 22.4219Z"
				fill="black"
			/>
		</svg>
	)
}

export default SortIcon
