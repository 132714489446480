import BreadCrumb from '../../uiElements/BreadCrumb'
import Submit from '../../uiElements/Submit'
import TextInput from '../../uiElements/TextInput'
import { css, StyleSheet } from 'aphrodite'
import Button from '../../uiElements/Button'
import { useData, useStatusMessage } from '../../store'
import { AddUsers } from '../userProfiles/AddUsers'
import { useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../constants'

const NewClient = () => {
	const { setActiveClientSubPage } = useData()
	const { setMessage } = useStatusMessage()
	const [name, setName] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			await axios.post(`${BASE_URL}/auth/client/register`, {
				clientName: name,
			})
			setMessage('Client register successfully...')
			setActiveClientSubPage('main')
		} catch (err) {
			console.log('err', err)
			setMessage(`Something went wrong: ${err.message}`)
		}
	}

	return (
		<div className={css(styles.container)}>
			<div>
				<BreadCrumb
					items={[
						{
							name: 'CLIENT LIST',
							clickHandler: () => setActiveClientSubPage('main'),
						},
						{ name: 'CREATE NEW CLIENT' },
					]}
				/>
				<form onSubmit={handleSubmit}>
					<TextInput
						labelText="CLIENT NAME"
						customInputStyles={styles.input}
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<Submit
						value="CREATE CLIENT"
						customStyles={styles.submit}
					/>
				</form>
			</div>
		</div>
	)
}

export default NewClient

const styles = StyleSheet.create({
	container: {
		paddingLeft: '3rem',
		paddingRight: '3rem',
	},
	submit: {
		marginTop: '1rem',
		marginLeft: 0,
	},
	input: {
		width: '20%',
	},
})
