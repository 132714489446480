import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { BASE_URL } from '../../constants'
import { useData } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import { DropDown } from '../../uiElements/DropDown'
import TextInput from '../../uiElements/TextInput'

const SingleUserProfile = () => {
	const { activeUserProfile, setActiveUserProfile } = useData()
	const { userName, deviceId, roles, _id } = activeUserProfile
	console.log('userName', userName, 'deviceId', deviceId, 'roles', roles)
	function mainUsersPage() {
		setActiveUserProfile({})
	}
	const updateUserRole = async (e) => {
		e.preventDefault()
		const userData = {
			userName,
			roles,
		}
		const userID = _id
		const res = await axios.put(
			`${BASE_URL}/auth/user/editUser/${userID}`,
			userData
		)
		console.log('res', res, res.data)
		console.log(userID, activeUserProfile)
	}
	return (
		<>
			<BreadCrumb
				items={[
					{ name: 'USERS', clickHandler: mainUsersPage },
					{ name: userName },
				]}
			/>
			<form onSubmit={updateUserRole}>
				<TextInput
					labelText="Username"
					customInputStyles={styles.customWidth}
					value={userName}
					readOnly
				/>
				<TextInput
					labelText="Device ID"
					customInputStyles={styles.customWidth}
					value={deviceId}
					readOnly
				/>
				<DropDown
					labelText="USER ROLE"
					value={roles}
					onChange={(val) =>
						setActiveUserProfile({
							...activeUserProfile,
							roles: val,
						})
					}
					customInputStyles={styles.customWidth}
					items={['trainee', 'trainer']}
				/>
				<div className={css(styles.btnContainer)}>
					<Button customStyles={styles.button}>UPDATE INFO</Button>
				</div>
			</form>
		</>
	)
}

export default SingleUserProfile

const styles = StyleSheet.create({
	btnContainer: {
		marginTop: 20,
	},
	customWidth: {
		width: '20%',
		boxSizing: 'content-box',
	},
})
