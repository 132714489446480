import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { BASE_URL, SUPER_ADMIN_TOKEN } from '../constants'
import OptionsIcon from '../svgs/OptionsIcon'
import EditButton from './EditButton'
import SortButton from './SortButton'

const TertiaryList = ({
	title,
	list,
	sort,
	edit,
	options,
	editClickHandler,
	customStyles,
}) => {
	return (
		<section className={css(styles.container, customStyles)}>
			<h2>
				{title} {sort && <SortButton />}{' '}
				{edit && <EditButton clickHandler={editClickHandler} />}
			</h2>
			<div className={css(styles.list)}>
				{list.length ? (
					list.map((listItem) => {
						return (
							<div
								key={uuid()}
								className={css(
									styles.singleItem,
									styles.flexBlock
								)}
							>
								<h3>{listItem}</h3>
								{options && <OptionsIcon />}
							</div>
						)
					})
				) : (
					<div className={css(styles.flexBlock, styles.noUser)}>
						<p>NO {title} FOUND</p>
					</div>
				)}
			</div>
		</section>
	)
}

export default TertiaryList

const styles = StyleSheet.create({
	container: {
		overflow: 'hidden',
		border: '2px solid rgba(219, 225, 232, 1)',
		padding: '1rem',
		paddingBottom: 0,
		marginTop: '2rem',
	},
	flexBlock: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	noUser: {
		justifyContent: 'center',
		height: '100%',
	},
	list: {
		height: 250,
		overflowY: 'auto',
		borderTop: '2px solid rgba(219, 225, 232, 1)',
		borderRight: '2px solid rgba(219, 225, 232, 1)',
		marginLeft: '-1rem',
		marginTop: '1rem',
		'::-webkit-scrollbar': {
			width: '.7rem',
			background: 'rgba(140, 140, 140, 1)',
			borderRadius: 5,
			position: 'absolute',
			display: 'block',
		},
		'::-webkit-scrollbar-thumb': {
			background: 'rgba(35, 34, 34, 1)',
			borderRadius: 5,
		},
	},
	singleItem: {
		borderBottom: '2px solid rgba(219, 225, 232, 1)',
		paddingLeft: '1rem',
		paddingRight: '1.5rem',
		':last-child': {
			borderBottom: 0,
		},
	},
})
