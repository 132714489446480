import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useState } from 'react'
import { BASE_URL } from '../../constants'
import { useData } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import Submit from '../../uiElements/Submit'
import TextInput from '../../uiElements/TextInput'

const AddAdmins = () => {
	const { activeClient, setActiveClientSubPage } = useData()

	const INITIAL_NEW_ADMIN_DATA = {
		userName: '',
		password: '',
	}

	const [adminDetails, setAdminDetails] = useState(INITIAL_NEW_ADMIN_DATA)

	const HandleInput = (input) => {
		setAdminDetails((prev) => {
			const newObj = { ...prev }
			newObj[input.target.name] = input.target.value
			return newObj
		})
		// console.log('adminDetails', adminDetails)
	}

	const submitHandler = async (e) => {
		e.preventDefault()
		const { userName, password } = adminDetails
		// console.log('userName, password', userName, password)
		try {
			// const { clientID } = await getUserData()
			const clientID = activeClient._id
			// console.log('clientID', clientID)
			const newAdmin = {
				clientID,
				userName,
				roles: 'admin',
				password,
			}
			const res = await axios.post(
				`${BASE_URL}/auth/user/register`,
				newAdmin
			)
			if (res.statusText === 'Created') {
				console.log('admin created')
				alert('admin created')
				setActiveClientSubPage('info')
			} else {
				alert(`message: ${res.data.message}`)
			}
			// console.log('res', res)
		} catch (err) {
			alert(`error: ${err.message}`)
			console.log(err)
		}
	}

	return (
		<div className={css(styles.container)}>
			<div className={css(styles.header)}>
				<BreadCrumb
					items={[
						{
							name: 'CLIENTS',
							clickHandler: () => setActiveClientSubPage('main'),
						},
						{
							name: activeClient.clientName,
							clickHandler: () => setActiveClientSubPage('info'),
						},
						{ name: 'NEW ADMIN' },
					]}
				/>
				<Button>ADD ADMIN</Button>
			</div>

			<form className={css(styles.form)} onSubmit={submitHandler}>
				<h3>CREATE NEW ADMIN</h3>
				<TextInput
					labelText="username"
					customInputStyles={styles.fullWidth}
					onChange={HandleInput}
					inputName="userName"
				/>
				<div>
					<label className={css(styles.label)}>
						<p>password</p>
					</label>
					<input
						type="password"
						name="password"
						onChange={HandleInput}
						className={css(styles.bar, styles.fullWidth)}
					/>
				</div>
				<div className={css(styles.space)}>
					<Submit value="submit" customStyles={styles.fullWidth} />
				</div>
			</form>
		</div>
	)
}

export default AddAdmins

const styles = StyleSheet.create({
	container: {
		paddingLeft: '3rem',
		paddingRight: '2rem',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	form: {
		width: '20%',
	},
	label: {
		fontWeight: 600,
	},
	space: {
		marginTop: '1.5rem',
	},
	fullWidth: {
		width: '100%',
		boxSizing: 'border-box',
	},
	bar: {
		border: 0,
		outline: 0,
		background: 'rgba(217, 217, 217, 1)',
		padding: '.5rem',
		borderRadius: '.5rem',
	},
})
