import axios from 'axios'
import { useState } from 'react'
import { BASE_URL } from '../constants'
import useHandleFormSubmit from './hooks/useHandleFormSubmit'

const Evaluations = () => {
	const INITIAL_EVALUATION_DATA = {
		stepId: '',
		data: '',
		score: '',
		module: '',
	}
	const [evaluationDetails, setEvaluationDetails] = useState(
		INITIAL_EVALUATION_DATA
	)

	const evaluationData = {
		data: evaluationDetails.data,
		score: evaluationDetails.score,
	}

	const evaluationLink = `${BASE_URL}/api/v1/evaluation/?stepId=${Number(
		evaluationDetails.stepId
	)}&module=${Number(evaluationDetails.module)}`

	const { handleInput, handleFormSubmitAsync: handleEvaluationSubmitAsync } =
		useHandleFormSubmit(
			INITIAL_EVALUATION_DATA,
			evaluationDetails,
			setEvaluationDetails,
			evaluationData,
			evaluationLink
		)

	return (
		<form onSubmit={handleEvaluationSubmitAsync}>
			<h1>Evaluations</h1>
			<label>
				StepId
				<input type="text" name="stepId" onChange={handleInput} />
			</label>
			<br />
			<label>
				Data <input type="text" name="data" onChange={handleInput} />
			</label>
			<br />
			<label>
				Score <input type="text" name="score" onChange={handleInput} />
			</label>
			<br />
			<label>
				Module
				<input type="text" name="module" onChange={handleInput} />
			</label>
			<br />
			<input type="submit" />
		</form>
	)
}

export default Evaluations
