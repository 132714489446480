import React, { useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import BreadCrumb from "../../uiElements/BreadCrumb";
import EvaluationReport from "../../uiElements/EvaluationReport";
import { SecondaryListOnlyUI } from "../../uiElements/SecondaryListOnlyUI";
import {
  useGetAllModules,
  useModuleAssignments,
} from "../../auth/hooks/moduleApiHooks";
import { useData } from "../../store";
import { useClientList, useClientUsers } from "../../auth/hooks/useClientUsers";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useGetModulesWithEvaluations } from "../../auth/hooks/useGetModulesWithEvaluations";
import { EvaluationSummary } from "./EvaluationSummary";
import { ListContainer } from "../../uiElements/v2/ListContainer";
import ListItem from "../../uiElements/ListItem";

const sortDirections = { inc: -1, unsorted: 0, dec: 1 };
function EvaluationUI() {
  const clientID = JSON.parse(localStorage.getItem("userDetails")).user
    .clientID;
  const allModules = useGetAllModules();
  const { setActiveEvaluationPage, activeEvaluationPage } = useData();
  const [modules] = useModuleAssignments(clientID);
  const userList = useClientList(`roles=trainee&hasEvaluations=true`);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedModuleId, setSelectedModuleId] = useState();
  const [selectedModuleName, setSelectedModuleName] = useState();
  const [sortDirection, setSortDirection] = useState(sortDirections.unsorted);
  function titleClickHandler(user) {
    setSelectedUserId(user._id);
  }
  const modulesWithEvaluations = useGetModulesWithEvaluations(clientID, selectedUserId, modules)
  
  useEffect(() => {
    setFilteredUsers(userList)
  }, [userList]);
  useEffect(()=> {
    if(sortDirection===sortDirections.unsorted)return
    console.log(sortDirection)
    setFilteredUsers(state => {
      let newUsers = [...state]
      if(sortDirection === sortDirections.dec){
        newUsers.sort((x,y)=>x.userName.localeCompare(y.userName))
      }else{
        newUsers.sort((x,y)=> y.userName.localeCompare(x.userName))
      }
      return newUsers
    })
  }, [sortDirection])
  function moduleClickHandler(mod) {
    setSelectedModuleId(mod._id);
    setSelectedModuleName(mod.name);
    setActiveEvaluationPage("evaluationReport");
  }
  useEffect(() => {
    if (selectedModuleId && selectedUserId) {
      setActiveEvaluationPage("evaluationReport");
    } else {
      setActiveEvaluationPage("main");
    }
  }, [selectedModuleId, selectedUserId]);
  function displayPage() {
    switch (activeEvaluationPage) {
      case "main":
        return (
          <>
            {selectedUserId && <EvaluationSummary allModules={modules} modules={modulesWithEvaluations} />}
            <div className={css(styles.flexBlock)}>
              <SecondaryListOnlyUI
                title="TRAINEES"
                sort
                ItemTitleProp="userName"
                customListStyles={styles.list}
                customListHeaderStyles={styles.listHeader}
                customListBodyStyles={styles.listBody}
                onClick={titleClickHandler}
                // closeClickHandler={deleteUser}
                items={filteredUsers}
                noDelete
                displayKey="userName"
                onClickSort={() =>
                  setSortDirection((state) => {
                    return state === sortDirections.unsorted ||
                      state === sortDirections.dec
                      ? sortDirections.inc
                      : sortDirections.dec;
                  })
                }
                customSingleItemStyles={styles.listSingleItem}
              />
              {selectedUserId && (
                <SecondaryListOnlyUI
                  title="EVALUATIONS"
                  ItemTitleProp="userName"
                  customListStyles={styles.list}
                  customListHeaderStyles={styles.listHeader}
                  customListBodyStyles={styles.listBody}
                  onClick={moduleClickHandler}
                  hideSearch
                  // closeClickHandler={deleteUser}
                  items={modulesWithEvaluations}
                  displayKey="name"
                  noDelete
                  customSingleItemStyles={styles.listSingleItem}
                />
              )}
            </div>
          </>
        );
      case "evaluationReport":
        return (
          <EvaluationReport
            userId={selectedUserId}
            moduleName={selectedModuleName}
            moduleId={selectedModuleId}
          />
        );
    }
  }

  return (
    <>
      <div className={css(styles.container)}>
        <header className={css(styles.flexBlock)}>
          <BreadCrumb
            // .concat(
            //   activeItem != null && { name: `${activeItem}` }
            // )
            items={[
              {
                name: "USERS",
                clickHandler: () => {
                  setSelectedModuleId();
                  setSelectedUserId();
                },
              },
              ...(selectedUserId != null
                ? [
                    {
                      name: `${
                        userList.find(({ _id }) => _id === selectedUserId)
                          .userName
                      }`,
                      clickHandler: () => {
                        // setActiveEvaluationPage('main')
                        setSelectedModuleId();
                      },
                    },
                  ]
                : []),
              ...(selectedModuleId != null
                ? [
                    {
                      name: `${
                        allModules.find(({ _id }) => _id === selectedModuleId)
                          .name
                      }`,
                    },
                  ]
                : []),
            ]}
          />
        </header>
        {displayPage()}
      </div>
    </>
  );
}

export default EvaluationUI;

const styles = StyleSheet.create({
  btn: {
    color: "#fff",
    background: "black",
    ":hover": {
      color: "#fff",
      background: "black",
    },
  },
  container: {
    paddingLeft: "3rem",
    paddingRight: "2rem",
  },
  flexBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop:'20px'
  },
  list: {
    width: "48%",
    borderRadius: 10,
    background: "rgba(251, 206, 7, 1)",
    padding: 0,
    border: "3px solid rgba(251, 206, 7, 1)",
  },
  listHeader: {
    padding: "1rem 1rem 0px",
  },
  listBody: {
    background: "#fff",
    paddingLeft: "1rem",
  },
  listSingleItem: {
    border: "2px solid rgba(219, 225, 232, 1)",
    margin: "1rem .5rem",
    borderRadius: 20,
  },
  activeListItem: {
    border: "2px solid rgba(219, 225, 232, 1)",
    margin: "1rem .5rem",
    borderRadius: 20,
    background: "red",
    fontColor: "white",
  },
});
