import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useClientInfo } from '../../auth/hooks/useUserInfo'
import { BASE_URL, SUPER_ADMIN_TOKEN } from '../../constants'
import { useData } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import SecondaryList from '../../uiElements/SecondaryList'
import { AddUsers } from './AddUsers'
import SingleUserProfile from './SingleUserProfile'

const UserProfiles = () => {
	const { activeUserProfileSubPage, setActiveUserProfileSubPage } = useData()
	const userDetails = JSON.parse(localStorage.getItem('userDetails'))
	const { activeUserProfile, setActiveUserProfile } = useData()
	const clientID = JSON.parse(localStorage.getItem('userDetails')).user
		.clientID
	console.log('userDetails in UserProfiles', userDetails, clientID)
	function titleClickHandler(user) {
		console.log('user', user)
		setActiveUserProfile({ ...user })
	}
	const client = useClientInfo(clientID)
	console.log('client', client)
	const deleteUser = async (user) => {
		const config = {
			headers: { authorization: `Bearer ${localStorage.getItem('userToken')}` },
		}
		if(!confirm(`Are you sure you want to delete user: ${user.userName}`)) return
		const userID = user._id
		const res = await axios.delete(
			`${BASE_URL}/auth/user/${userID}`,
		)
		console.log('res', res.data)
		console.log('user', user, user._id)
	}
	function displayPage() {
		switch (activeUserProfileSubPage) {
			case 'main':
				return (
					<>
						<header className={css(styles.flexBlock)}>
							<BreadCrumb items={[{ name: 'USERS' }]} />
							<Button
								clickHandler={() =>
									setActiveUserProfileSubPage('add users')
								}
							>
								ADD NEW USER
							</Button>
						</header>
						{client?.clientName && <div className={css(styles.flexBlock)}>
							{client.clientName!=='Ultratech' && <SecondaryList
								title="TRAINERS"
								sort
								fetchURL={`/client/${clientID}/users?roles=trainer`}
								ItemTitleProp="userName"
								customListStyles={styles.list}
								customListHeaderStyles={styles.listHeader}
								customListBodyStyles={styles.listBody}
								titleClickHandler={titleClickHandler}
								closeClickHandler={deleteUser}
								customSingleItemStyles={styles.listSingleItem}
							/>}
							<SecondaryList
								title={client.clientName==='Ultratech'?"USERS":"TRAINEES"}
								sort
								fetchURL={`/client/${clientID}/users?roles=trainee`}
								ItemTitleProp="userName"
								customListStyles={styles.list}
								customListHeaderStyles={styles.listHeader}
								customListBodyStyles={styles.listBody}
								titleClickHandler={titleClickHandler}
								closeClickHandler={deleteUser}
								customSingleItemStyles={styles.listSingleItem}

							/>
						</div>}
					</>
				)
			case 'add users':
				return <AddUsers />
			case 'single user profile':
				return <SingleUserProfile />
		}
	}

	useEffect(() => {
		if (Object.keys(activeUserProfile).length) {
			setActiveUserProfileSubPage('single user profile')
		} else {
			setActiveUserProfileSubPage('main')
		}
	}, [activeUserProfile])

	return <div className={css(styles.container)}>{displayPage()}</div>
}

export default UserProfiles

const styles = StyleSheet.create({
	container: {
		paddingLeft: '3rem',
		paddingRight: '2rem',
	},
	flexBlock: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	list: {
		width: '48%',
		borderRadius: 10,
		background: 'rgba(251, 206, 7, 1)',
		padding: 0,
		border: '3px solid rgba(251, 206, 7, 1)',
	},
	listHeader: {
		padding: '1rem 1rem 0px',
	},
	listBody: {
		background: '#fff',
		paddingLeft: '1rem',
	},
	listSingleItem: {
		border: '2px solid rgba(219, 225, 232, 1)',
		margin: '1rem .5rem',
		borderRadius: 20,
		':hover': {
			background: 'red',
			border: '2px solid red',
			color: '#fff',
			':nth-child(n) button': {
				color: '#fff',
			},
			':nth-child(n) svg': {
				fill: '#fff',
				filter: 'invert(100%)',
			},
		},
	},
})
