import { css, StyleSheet } from 'aphrodite'
import { useEffect, useRef, useState } from 'react'
import { useClientInfo } from '../auth/hooks/useUserInfo'
import React from 'react'
import UserAvatarIcon from '../svgs/UserAvatarIcon'

const UserDetailsCard = ({ unMount }) => {
	const user = JSON.parse(localStorage.getItem('userDetails')).user
	const client = useClientInfo(user.clientID)
	const ref = useRef()

	useEffect(() => {
		const listener = (e) => {
			console.log('listener')
			if (!ref.current.contains(e.target)) unMount()
		}
		setTimeout(() => window.addEventListener('click', listener), 500)

		return () => window.removeEventListener('click', listener)
	}, [])

	return (
		<div ref={ref} className={css(styles.card)}>
			<p>User: {user?.userName}</p>
			<p>Client: {client?.clientName}</p>
			<a onClick={() => localStorage.clear()} href="">
				Log out
			</a>
		</div>
	)
}

const UserDetails = () => {
	const [show, setShow] = useState()

	return (
		<div>
			<button
				className={css(styles.avatarIcon)}
				onClick={() => setShow(!show)}
			>
				<UserAvatarIcon />
			</button>
			{show && <UserDetailsCard unMount={() => setShow(false)} />}
		</div>
	)
}

export default UserDetails

const styles = StyleSheet.create({
	card: {
		width: 230,
		height: 125,
		right: 30,
		background: 'white',
		borderRadius: 5,
		padding: 3,
		position: 'absolute',
		boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
		padding: '1.2rem',
	},
	avatarIcon: {
		cursor: 'pointer',
		background: 'none',
		border: 0,
		padding: 0,
		lineHeight: 0,
		borderRadius: '50%',
		outlineColor: 'rgb(140, 140, 140)',
	},
})
