const EditIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.3438 19.2266H1.65625C1.20684 19.2266 0.84375 19.5896 0.84375 20.0391V20.9531C0.84375 21.0648 0.935156 21.1562 1.04688 21.1562H20.9531C21.0648 21.1562 21.1562 21.0648 21.1562 20.9531V20.0391C21.1562 19.5896 20.7932 19.2266 20.3438 19.2266ZM4.54316 17.0938C4.59395 17.0938 4.64473 17.0887 4.69551 17.0811L8.96621 16.332C9.01699 16.3219 9.06524 16.299 9.10078 16.2609L19.8639 5.49785C19.8874 5.47436 19.9061 5.44646 19.9188 5.41574C19.9316 5.38503 19.9381 5.3521 19.9381 5.31885C19.9381 5.28559 19.9316 5.25267 19.9188 5.22195C19.9061 5.19123 19.8874 5.16333 19.8639 5.13984L15.6439 0.917383C15.5957 0.869141 15.5322 0.84375 15.4637 0.84375C15.3951 0.84375 15.3316 0.869141 15.2834 0.917383L4.52031 11.6805C4.48223 11.7186 4.45937 11.7643 4.44922 11.815L3.7002 16.0857C3.67549 16.2218 3.68432 16.3618 3.72591 16.4936C3.7675 16.6254 3.84059 16.7452 3.93887 16.8424C4.10645 17.0049 4.31719 17.0938 4.54316 17.0938Z"
				fill="black"
			/>
		</svg>
	)
}

export default EditIcon
