import React, { useEffect, useRef } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { useModal } from '../store'
import { AddUsers } from '../pages/userProfiles/AddUsers'
export const Modal = () => {
	const { component, setComponent } = useModal()
	const Content = component
	const ref = useRef()
	useEffect(() => {
		// setComponent(<AddUsers />)
		// console.log(component)
		window.addEventListener('click', (e) => {
			if (e.target === ref.current) setComponent()
		})
	}, [])
	return component ? (
		<div ref={ref} className={css(styles.container)}>
			<div className={css(styles.box)}>{component}</div>
		</div>
	) : null
}

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0,0,0,0.3)',
	},
	box: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		zIndex: 1,
		// width: '50%',
		// height:'50%',
		// background: 'white',
		transform: 'translate(-50%,-50%)',
	},
})
