import { css, StyleSheet } from 'aphrodite'

const TextInput = ({
	labelText,
	customLabelStyles,
	customInputStyles,
	required,
	defaultValue,
	value,
	onChange,
	inputName,
	readOnly,
}) => {
	return labelText ? (
		<div style={{display: 'block'}}>
		<label className={css(styles.label, customLabelStyles)}>
			<p> {labelText} </p>
			<input
				className={css(styles.bar, customInputStyles)}
				name={inputName}
				type="text"
				required={required}
				defaultValue={defaultValue}
				value={value}
				onChange={onChange && onChange}
				readOnly={readOnly}
			/>
		</label>

		</div>
	) : (
		<div>

			<input
				className={css(styles.bar, customInputStyles)}
				name={inputName}
				type="text"
				required={required}
				defaultValue={defaultValue}
				value={value}
				onChange={onChange && onChange}
				readOnly={readOnly}
			/>
		</div>
	)
}

export default TextInput

const styles = StyleSheet.create({
	bar: {
		display:'block',
		border: 0,
		outline: 0,
		background: 'rgba(217, 217, 217, 1)',
		padding: '.5rem',
		borderRadius: '.5rem',
	},
	label: {
		display:'block',
		fontWeight: 600,
		// textAlign: 'left'
	},
})
