import { useEffect, useState } from 'react'
import ListHeader from '../../uiElements/ListHeader'
import ListItem from '../../uiElements/ListItem'
import uuid from 'react-uuid'
import NewClient from './NewClient'
import ClientInfo from './ClientInfo'
import { useData } from '../../store'
import ModuleAccess from './ModuleAccess'
import AddAdmins from './AddAdmins'
import axios from 'axios'
import { BASE_URL, SUPER_ADMIN_TOKEN } from '../../constants'

const Clients = () => {
	/* CLIENTS DATA FOR TESTING */
	const allClients = [
		{
			id: 347924,
			name: 'CLIENT 1',
		},
		{
			id: 393749,
			name: 'CLIENT 2',
		},
		{
			id: 948732,
			name: 'CLIENT 3',
		},
		{
			id: 547838,
			name: 'CLIENT 4',
		},
		{
			id: 843281,
			name: 'CLIENT 5',
		},
		{
			id: 498124,
			name: 'CLIENT 6',
		},
		{
			id: 479826,
			name: 'CLIENT 7',
		},
		{
			id: 479826,
			name: 'CLIENT 8',
		},
	]
	const {
		activeClient,
		setActiveClient,
		activeClientSubPage,
		setActiveClientSubPage,
	} = useData()

	const [clientsData, setClientsData] = useState([])

	useEffect(() => {
		const fetchClients = async () => {
			const config = {
				headers: { authorization: `Bearer ${SUPER_ADMIN_TOKEN}` },
			}
			const res = await axios.get(`${BASE_URL}/client`)
			console.log('res', res.data)
			setClientsData(res.data)
		}
		fetchClients()
	}, [])

	useEffect(() => {
		if (activeClientSubPage === 'main') setActiveClient('')
	}, [activeClientSubPage])

	function displayPage() {
		switch (activeClientSubPage) {
			case 'main':
				return (
					<>
						<ListHeader
							title="CLIENTS"
							btnContent="CREATE CLIENT"
							clickHandler={() =>
								setActiveClientSubPage('create')
							}
						/>
						{clientsData.map((client) => (
							<ListItem
								key={uuid()}
								title={client.clientName}
								clickHandler={() => {
									setActiveClient(client)
								}}
							/>
						))}
					</>
				)
			case 'create':
				return <NewClient />
			case 'info':
				return <ClientInfo />
			case 'module access':
				return <ModuleAccess />
			case 'add admins':
				return <AddAdmins />
		}
	}

	useEffect(() => {
		activeClient.clientName && setActiveClientSubPage('info')
	}, [activeClient])

	return <div>{displayPage()}</div>
}

export default Clients
