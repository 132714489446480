import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import RightArrowIcon from '../svgs/RightArrowIcon'
import Button from './Button'

const BreadCrumb = ({ items }) => {
  return (
    <nav aria-label="breadcrumbs">
      <ol className={css(styles.navList)}>
        {items.map((item, index) => {
          const isLastItem = index === items.length - 1
          // console.log('items in BreadCrumb', items)
          return (
            <li key={uuid()} aria-current={isLastItem ? 'page' : null}>
              {isLastItem ? (
                <h1 className={css(styles.title)}>{item.name}</h1>
              ) : (
                <Button
                  clickHandler={item.clickHandler}
                  customStyles={[styles.inactive, styles.btn]}
                >
                  {item.name}
                </Button>
              )}
              {!isLastItem && (
                <span aria-hidden="true">
                  {' '}
                  <RightArrowIcon />
                  &nbsp;
                </span>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default BreadCrumb

const styles = StyleSheet.create({
  navList: {
    listStyleType: 'none',
    display: 'flex',
    padding: 0,
    margin: '1.25rem 0',
  },
  title: {
    fontSize: '2rem',
    margin: 0,
  },
  inactive: {
    color: 'rgba(217, 217, 217, 1)',
  },
  btn: {
    background: 'none',
    fontSize: '2rem',
    fontWeight: 600,
    padding: 0,
  },
})
