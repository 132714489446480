import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";
export const EvaluationSummary = ({ modules, allModules }) => {
  const evalInfo = useMemo(() => {
    let evalInfo = [];
    modules.forEach((module) => {
      // module.evalInfo = {total: module.evaluation.length}
      evalInfo.push({
        moduleName: module.name,
        total: module.evaluations.length,
      });
      let finished = 0;
      module.evaluations.forEach((evaluation) => {
        if (evaluation.score === "1") {
          finished += 1;
        }
      });
      evalInfo[evalInfo.length - 1].completed = finished;
    });
    console.log(evalInfo);
    return evalInfo;
  }, [modules]);
  return (
    evalInfo.length && (
      <div className={css(styles.container)}>
        <p>
          Modules Attempted:{" "}
          {evalInfo.reduce((x, y) => x + (y.total === 0 ? 0 : 1), 0)}/
          {allModules.length}
        </p>
        <p>
          Modules Completed:{" "}
          {evalInfo.reduce((x, y) => x + (y.completed === y.total ? 1 : 0), 0)}/
          {allModules.length}
        </p>
      </div>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    position:'absolute',
    top:94,
    left:350
  },
});
