const data = [
	{
		userName: 'aticsun',
		factoryName: 'Everloop',
		modules: [
			{
				moduleName: 'first',
				result: 'fail',
			},
			{
				moduleName: 'second',
				result: 'pass',
			},
			{
				moduleName: 'third',
				result: 'pass',
			},
		],
	},
	{
		userName: 'vertigoowl',
		factoryName: 'Everloop',
		modules: [
			{
				moduleName: 'first',
				result: 'pass',
			},
			{
				moduleName: 'second',
				result: 'pass',
			},
			{
				moduleName: 'third',
				result: 'pass',
			},
		],
	},
	{
		userName: 'snowstorm',
		factoryName: 'Everloop',
		modules: [
			{
				moduleName: 'first',
				result: 'pass',
			},
			{
				moduleName: 'second',
				result: 'fail',
			},
			{
				moduleName: 'third',
				result: 'pass',
			},
		],
	},
	{
		userName: 'deepImpact',
		factoryName: 'Bruchies',
		modules: [
			{
				moduleName: 'first',
				result: 'pass',
			},
			{
				moduleName: 'second',
				result: 'fail',
			},
			{
				moduleName: 'third',
				result: 'pass',
			},
			{
				moduleName: 'fourth',
				result: 'fail',
			},
		],
	},
	{
		userName: 'pieLeaf',
		factoryName: 'Bruchies',
		modules: [
			{
				moduleName: 'first',
				result: 'fail',
			},
			{
				moduleName: 'second',
				result: 'fail',
			},
			{
				moduleName: 'fourth',
				result: 'pass',
			},
			{
				moduleName: 'third',
				result: 'pass',
			},
		],
	},
	{
		userName: 'treestump',
		factoryName: 'Sky Tours',
		modules: [
			{
				moduleName: 'first',
				result: 'fail',
			},
			{
				moduleName: 'second',
				result: 'pass',
			},
			{
				moduleName: 'third',
				result: 'pass',
			},
		],
	},
	{
		userName: 'yodalemon',
		factoryName: 'Sky Tours',
		modules: [
			{
				moduleName: 'first',
				result: 'pass',
			},
			{
				moduleName: 'second',
				result: 'pass',
			},
			{
				moduleName: 'third',
				result: 'pass',
			},
		],
	},
	{
		userName: 'webyodapie',
		factoryName: 'Sky Tours',
		modules: [
			{
				moduleName: 'first',
				result: 'pass',
			},
			{
				moduleName: 'second',
				result: 'fail',
			},
			{
				moduleName: 'third',
				result: 'fail',
			},
		],
	},
	{
		userName: 'mineflood',
		factoryName: 'Sky Tours',
		modules: [
			{
				moduleName: 'first',
				result: 'pass',
			},
			{
				moduleName: 'second',
				result: 'fail',
			},
			{
				moduleName: 'third',
				result: 'pass',
			},
		],
	},
]

export default data

// structure of the above data
// [
//   {
//     username // string
//     factoryname: '',
//     modules [
//       {
//         modulename: '', // string,
//         result: 'pass || fail',
//       }
//     ]
//   }
// ]
