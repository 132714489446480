import { useEffect, useState } from 'react'
import HeaderButton from './HeaderButton'
import { css, StyleSheet } from 'aphrodite'
import UserAvatarIcon from '../svgs/UserAvatarIcon'
import GearIcon from '../svgs/GearIcon'
import { useData } from '../store'
import { getUserData } from '../auth/getUserData'
import { useClientInfo, userUserInfo } from '../auth/hooks/useUserInfo'
import UserDetails from './UserDetails'

const Header = () => {
	const { currentPage, setCurrentPage } = useData()
	const user = userUserInfo()
	const client = useClientInfo(user.clientID)
	return (
		<header className={css(styles.header)}>
			<img
				src={client.logoUrl}
				height="40px"
				style={{ marginRight: '2rem', marginLeft: '0px' }}
			/>
			<HeaderButton
				clickHandler={() => setCurrentPage('user profiles')}
				active={currentPage === 'user profiles'}
			>
				USER PROFILES
			</HeaderButton>
			<HeaderButton
				clickHandler={() => setCurrentPage('evaluation')}
				active={currentPage === 'evaluation'}
			>
				EVALUATION
			</HeaderButton>
			<HeaderButton
				clickHandler={() => setCurrentPage('reports')}
				active={currentPage === 'reports'}
			>
				REPORTS
			</HeaderButton>
			{user?.roles === 'superAdmin' && (
				<>
					<HeaderButton
						clickHandler={() => setCurrentPage('clients')}
						active={currentPage === 'clients'}
					>
						CLIENT MANAGEMENT
					</HeaderButton>
					{/* <HeaderButton
						clickHandler={() => setCurrentPage('license')}
						active={currentPage === 'license'}
					>
						LICENSE MANAGEMENT
					</HeaderButton>
					<HeaderButton
						clickHandler={() => setCurrentPage('analytics')}
						active={currentPage === 'analytics'}
					>
						ANALYTICS
					</HeaderButton>
					<HeaderButton
						clickHandler={() => setCurrentPage('support')}
						active={currentPage === 'support'}
					>
						SUPPORT
					</HeaderButton> */}
				</>
			)}
			{client.cmsAvailable && (
				<HeaderButton
					clickHandler={() => setCurrentPage('content management')}
					active={currentPage === 'content management'}
				>
					CONTENT MANAGEMENT
				</HeaderButton>
			)}

			<HeaderButton
				clickHandler={() => setCurrentPage('modules')}
				active={currentPage === 'modules'}
			>
				TRAINING MODULES
			</HeaderButton>

			<div className={css(styles.metaContainer)}>
				<UserDetails />
				<GearIcon />
			</div>
		</header>
	)
}

export default Header

const styles = StyleSheet.create({
	header: {
		borderBottom: '2px solid rgba(219, 225, 232, 1)',
		display: 'flex',
		padding: '1rem 2rem 0',
	},
	metaContainer: {
		display: 'inline-block',
		display: 'flex',
		alignSelf: 'center',
		marginLeft: 'auto',
		gap: '1.5rem',
	},
})
