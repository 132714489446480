import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { BASE_URL, SUPER_ADMIN_TOKEN } from '../constants'
import { useData } from '../store'
import Button from './Button'
import CloseButton from './CloseButton'
import SearchBar from './SearchBar'
import SortButton from './SortButton'

const SecondaryList = ({
  title,
  sort,
  fetchURL,
  ItemTitleProp,
  customListStyles,
  customListHeaderStyles,
  customListBodyStyles,
  customSingleItemStyles,
  titleClickHandler,
  closeClickHandler,
  items = [],
  activeModule,
}) => {
  const [listData, setListData] = useState([])
  const [searchedListData, setSearchedListData] = useState(listData)
  const [sortList, setSortList] = useState('')
  const { currentPage } = useData()

  function sortListClickHanlder() {
    if (sortList === 'asc') {
      setSortList('desc')
    } else {
      setSortList('asc')
    }
  }
  function searchList(e) {
    const text = e.target.value
    const pattern = new RegExp(text, 'ig')
    const newList = []
    listData.forEach((listItem) => {
      if (pattern.test(listItem.userName)) {
        console.log('text matched\n', text, listItem)
        newList.push(listItem)
      }
    })
    if (text) {
      setSearchedListData([...newList])
    } else {
      setSearchedListData([...listData])
    }
    console.log('listItem', text, pattern, '\nnewList', newList)
  }
  useEffect(() => {
    const fetchList = async () => {
      const config = {
        headers: { authorization: `Bearer ${SUPER_ADMIN_TOKEN}` },
      }
      const res = await axios.get(`${BASE_URL}${fetchURL}`)
      console.log('res', res.data)
      setListData(res.data)
    }
    fetchList()
  }, [])
  useEffect(() => {
    let customList = listData
    const compare = (fsEle, sdEle) => {
      const fsElename = fsEle.userName.toLowerCase()
      const sdElename = sdEle.userName.toLowerCase()
      console.log('fsElename, sdElename', fsElename, sdElename)
      if (fsElename < sdElename) {
        return -1
      } else if (fsElename > sdElename) {
        return 1
      }
      return 0
    }
    if (sortList === 'asc') {
      customList.sort(compare)
      console.log('listData in asc', customList)
      setListData([...customList])
    } else if (sortList === 'desc') {
      customList.sort(compare).reverse()
      console.log('listData in desc', customList)
      setListData([...customList])
    }
  }, [sortList])

  useEffect(() => {
    setSearchedListData([...listData])
  }, [listData])

  return (
    <section className={css(styles.container, customListStyles)}>
      <div className={css(sort && styles.flexBlock, customListHeaderStyles)}>
        <h2>{title}</h2>

        {sort ? (
          <div className={css(styles.flexBlock)}>
            {sort && <SortButton clickHandler={sortListClickHanlder} />}
            <SearchBar
              customStyles={styles.sortSearchBarStyles}
              changeHandler={searchList}
            />
          </div>
        ) : (
          <SearchBar
            customStyles={styles.searchBarStyles}
            changeHandler={searchList}
          />
        )}
      </div>
      <div className={css(styles.list, customListBodyStyles)}>
        {searchedListData.length ? (
          searchedListData.map((listItem) => (
            <div
              key={uuid()}
              className={css(styles.singleItem, customSingleItemStyles)}
              onClick={() => {
                titleClickHandler(listItem)
                setTraineeTitle(listItem[ItemTitleProp])
              }}
            >
              <Button customStyles={styles.itemBtn}>
                {listItem[ItemTitleProp]}
              </Button>
              <div className={css(styles.flexBlock, styles.flexGap)}>
                {listItem.deviceId && <p>DEVICE ID: {listItem.deviceId} </p>}
                <CloseButton
                  clickHandler={(e) => {
                    console.log(e)
                    e.stopPropagation()

                    closeClickHandler(listItem)
                  }}
                />
              </div>
            </div>
          ))
        ) : (
          <div className={css(styles.flexBlock, styles.noUser)}>
            <p>NO {title.slice(0, -1)} FOUND</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default SecondaryList

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    border: '2px solid rgba(219, 225, 232, 1)',
    padding: '1rem',
    paddingBottom: 0,
    width: '40%',
    height: 400,
    maxHeight: 450,
  },
  flexBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noUser: {
    justifyContent: 'center',
    height: '100%',
  },
  flexGap: {
    gap: '1.5rem',
  },
  list: {
    height: '75%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderTop: '2px solid rgba(219, 225, 232, 1)',
    borderRight: '2px solid rgba(219, 225, 232, 1)',
    marginLeft: '-1rem',
    marginTop: '1rem',
    '::-webkit-scrollbar': {
      width: '.7rem',
      background: 'rgba(140, 140, 140, 1)',
      borderRadius: 5,
      position: 'absolute',
      display: 'block',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(35, 34, 34, 1)',
      borderRadius: 5,
    },
  },
  singleItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '2px solid rgba(219, 225, 232, 1)',
    paddingLeft: '1rem',
    paddingRight: '1.5rem',
    cursor: 'pointer',
    ':last-child': {
      borderBottom: 0,
    },
  },
  searchBarStyles: {
    width: '70%',
  },
  sortSearchBarStyles: {
    width: '75%',
    marginLeft: 'auto',
    alignSelf: 'center',
  },
  itemBtn: {
    fontSize: '1.17rem',
    background: 'none',
    display: 'block',
    margin: '1.17rem 0',
    padding: 0,
  },
})
