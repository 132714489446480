import { Navigate, Outlet } from 'react-router'
import { isTokenValid } from './hooks/authenticate'

const ProtectedRoutes = () => {
	return isTokenValid('userToken', 'userTimestamp') ? (
		<Outlet />
	) : (
		<Navigate to="/login" />
	)
}

export default ProtectedRoutes
