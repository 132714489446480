const SearchIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M31.2501 31.2493L24.1472 24.1339L31.2501 31.2493ZM28.0834 14.6243C28.0834 18.1937 26.6655 21.6169 24.1416 24.1408C21.6176 26.6648 18.1945 28.0827 14.6251 28.0827C11.0557 28.0827 7.63253 26.6648 5.1086 24.1408C2.58468 21.6169 1.16675 18.1937 1.16675 14.6243C1.16675 11.055 2.58468 7.6318 5.1086 5.10787C7.63253 2.58394 11.0557 1.16602 14.6251 1.16602C18.1945 1.16602 21.6176 2.58394 24.1416 5.10787C26.6655 7.6318 28.0834 11.055 28.0834 14.6243V14.6243Z"
				stroke="#8C8C8C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	)
}

export default SearchIcon
