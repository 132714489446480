import { css, StyleSheet } from 'aphrodite'

const Analytics = () => {
  return (
    <div>
      <header className={css(styles.header)}>
        <h1 className={css(styles.title)}> ANALYTICS </h1>
      </header>
      <div className={css(styles.container)}>
        <p>
          Realtime Analytics
        </p>
        App usage
        <p>
          Modules per trainee (guided, self and evalutaions) and progress tracking
        </p>
        Number of training sessions (multiplayer and solo)
        <p>
          Performance tracking
        </p>
        Advanced Analytics
        <p>
          View All Reports
        </p>
        <p>
          Custom Reports
        </p>
      </div>
    </div>
  )
}

export default Analytics

const styles = StyleSheet.create({
  container: {
    paddingLeft: '3rem'
  },
  header: {
    display: 'flex',
    padding: '0 2rem 0 3rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '2px solid rgba(219, 225, 232, 1)',
  },
  title: {
    fontSize: '2rem',
  },
})