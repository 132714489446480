const WrenchIcon = ({ wrenchStyle }) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={wrenchStyle}
		>
			<path
				d="M13 0C5.82029 0 0 5.82029 0 13C0 20.1797 5.82029 26 13 26C20.1797 26 26 20.1797 26 13C26 5.82029 20.1797 0 13 0ZM16.5404 5.19714C17.0329 5.19575 17.5266 5.27913 17.9956 5.44787L15.4073 8.03612L16.1215 9.8785L17.9638 10.5926L20.5521 8.0044C21.0921 9.50512 20.7612 11.2501 19.5587 12.4525C18.3563 13.655 16.6129 13.9859 15.1122 13.446L7.75363 20.8029L5.19714 18.2463L12.5541 10.8878C12.0141 9.38713 12.3451 7.64376 13.5475 6.44128C14.3742 5.61459 15.4569 5.20019 16.5404 5.19714V5.19714ZM7.20618 17.7322C6.91275 18.0256 6.91275 18.5004 7.20618 18.7938C7.49959 19.0872 7.97442 19.0872 8.26783 18.7938C8.56124 18.5004 8.56124 18.0256 8.26783 17.7322C7.94978 17.4431 7.48022 17.4664 7.20618 17.7322Z"
				fill="black"
			/>
		</svg>
	)
}

export default WrenchIcon
