import { useState } from 'react'
import ListHeader from '../../uiElements/ListHeader'
import ListItem from '../../uiElements/ListItem'
import uuid from 'react-uuid'

const Support = () => {
	/* tickets DATA FOR TESTING */
	const allTickets = [
		{
			id: 347924,
			subject: 'Tantos eructantibus pro permittam',
		},
		{
			id: 393749,
			subject: 'Turbabaminique poteratis, periisseque.',
		},
		{
			id: 948732,
			subject: 'Trepidares pressere, iubabo adsciscerentque',
		},
		{
			id: 547838,
			subject: 'Aderamus. Inducta reliquisti, adfinitatisque',
		},
		{
			id: 843281,
			subject: 'Per confertisque, machinabaris aut clam',
		},
		{
			id: 498124,
			subject: 'Spernebantque, succedamus. Subitque tardisque',
		},
		{
			id: 479826,
			subject: 'Operata edidistique socerorumque prensaque',
		},
	]

	const [ticketsData, setTicketsData] = useState(allTickets)

	return (
		<div>
			<ListHeader title="SUPPORT" btnContent="ADD TICKET" />
			{ticketsData.map((ticket) => (
				<ListItem key={uuid()} title={ticket.subject} />
			))}
		</div>
	)
}

export default Support
