import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Route, Navigate } from 'react-router'
import { BASE_URL } from '../constants'
import Submit from '../uiElements/Submit'
import TextInput from '../uiElements/TextInput'

function LoginForm() {
	const [isLoggedIn, setIsLoggedIn] = useState()
	const INITIAL_LOGIN_DATA = {
		userName: '',
		password: '',
	}
	const [userDetails, setUserDetails] = useState(INITIAL_LOGIN_DATA)

	const HandleInput = (input) => {
		setUserDetails((prev) => {
			const newObj = { ...prev }
			newObj[input.target.name] = input.target.value
			return newObj
		})
		console.log(userDetails)
	}

	const checkFormData = () => {
		if (userDetails.userName !== '' && userDetails.password !== '') {
			return true
		}
		return false
	}

	const onLogin = async () => {
		const config = { headers: { 'Content-type': 'application/json' } }
		const loginData = {
			userName: userDetails.userName,
			password: userDetails.password,
		}

		const loginLink = `${BASE_URL}/auth/user/login`
		const response = await axios.post(loginLink, loginData, config)
		console.log(response)
		const data = response.data
		console.log('response.data', data)
		localStorage.setItem('userToken', data.token)
		localStorage.setItem('userTimestamp', new Date().getTime())
		localStorage.setItem('userDetails', JSON.stringify(data))
		axios.defaults.headers.common.authorization = `Bearer ${data.token}`
		setIsLoggedIn(true)
	}

	const HandleLoginSubmitAsync = async (e) => {
		e.preventDefault()
		console.log('e', e)
		if (checkFormData()) {
			try {
				await onLogin()
			} catch (error) {
				console.log(error)
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log('error in response', error.response)
					alert(error.message)
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log('error in request', error.request)
					alert(error.request)
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('error in some other place')
				}
			}
		} else {
			console.log('invalid userDetails', userDetails)
			alert('Please fill valid details')
		}
	}

	return isLoggedIn ? (
		<Navigate to="/" />
	) : (
		<div className={css(styles.container)} >
			<div className={css(styles.bg)} >
				<img src="https://autovrse.in/wp-content/uploads/2019/08/cropped-Logo.png" alt="Logo" />
				<form onSubmit={HandleLoginSubmitAsync} className={css(styles.form)} >
					<h1 className={css(styles.title)} >Welcome to VR Training Management</h1>
					<p className={css(styles.subTitle)}>Please enter your credentials to login</p>
					<TextInput
						labelText="Username"
						inputName="userName"
						required onChange={HandleInput}
						customLabelStyles={styles.space}
						customInputStyles={styles.fullWidth}
					/>
					<label className={css(styles.label)} >
						<p className={css(styles.space)}>
							Password
						</p>
						<input
							type="password"
							name="password"
							onChange={HandleInput}
							className={css(styles.bar, styles.fullWidth)}
						/>
					</label>
					<div className={css(styles.space)} >
						<Submit value="LOGIN" customStyles={styles.fullWidth} />
					</div>
				</form>
			</div>
		</div>

	)
}

export default LoginForm

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
	bg: {
		background: 'rgb(34, 52, 67)',
		display: 'flex',
		alignItems: 'center'
	},
	form: {
		background: '#fff',
		margin: '1.5rem 0',
		marginRight: '1.5rem',
		padding: '1.5rem',
		width: '28rem'
	},
	title: {
		fontSize: '1rem',
		color: 'rgba(86, 84, 85, 1)',
	},
	subTitle: {
		fontSize: '.8rem',
		color: 'rgba(180, 180, 180, 1)',
	},
	space: {
		marginTop: '2rem',
		display: 'block',
	},
	fullWidth: {
		width: '100%',
		boxSizing: 'border-box',
	},
	bar: {
		border: 0,
		outline: 0,
		background: 'rgba(217, 217, 217, 1)',
		padding: '.5rem',
		borderRadius: '.5rem',
	},
	label: {
		fontWeight: 600,
	},
})
