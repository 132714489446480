const CloseIcon = (props) => {
	return (
		<svg
			{...props}
			style={{cursor: 'pointer'}}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.9999 0.445312C9.71442 0.445313 7.48028 1.12303 5.57997 2.39277C3.67967 3.66252 2.19857 5.46725 1.32396 7.57875C0.449343 9.69025 0.220504 12.0137 0.666378 14.2552C1.11225 16.4968 2.21281 18.5558 3.82889 20.1719C5.44496 21.788 7.50396 22.8885 9.74552 23.3344C11.9871 23.7803 14.3105 23.5514 16.422 22.6768C18.5335 21.8022 20.3382 20.3211 21.608 18.4208C22.8777 16.5205 23.5555 14.2863 23.5555 12.0009C23.5555 8.93614 22.338 5.99694 20.1709 3.82986C18.0038 1.66277 15.0646 0.445313 11.9999 0.445312ZM17.7777 16.4064C17.9692 16.598 18.0768 16.8578 18.0768 17.1286C18.0768 17.3995 17.9692 17.6593 17.7777 17.8509C17.5861 18.0424 17.3263 18.15 17.0555 18.15C16.7846 18.15 16.5248 18.0424 16.3332 17.8509L11.9999 13.5175L7.66656 17.8653C7.57172 17.9602 7.45912 18.0354 7.33521 18.0867C7.21129 18.138 7.07847 18.1645 6.94434 18.1645C6.81021 18.1645 6.6774 18.138 6.55348 18.0867C6.42956 18.0354 6.31696 17.9602 6.22212 17.8653C6.12728 17.7705 6.05204 17.6579 6.00071 17.534C5.94938 17.41 5.92297 17.2772 5.92297 17.1431C5.92297 17.009 5.94938 16.8761 6.00071 16.7522C6.05204 16.6283 6.12728 16.5157 6.22212 16.4209L10.5555 12.0586L6.09934 7.56642C5.9078 7.37488 5.80019 7.11509 5.80019 6.8442C5.80019 6.57332 5.9078 6.31352 6.09934 6.12198C6.29089 5.93043 6.55068 5.82282 6.82156 5.82282C7.09245 5.82282 7.35224 5.93043 7.54379 6.12198L11.9999 10.6286L16.456 6.17253C16.5509 6.07769 16.6634 6.00246 16.7874 5.95113C16.9113 5.8998 17.0441 5.87338 17.1782 5.87338C17.3124 5.87338 17.4452 5.8998 17.5691 5.95113C17.693 6.00246 17.8056 6.07769 17.9005 6.17253C17.9953 6.26738 18.0705 6.37997 18.1219 6.50389C18.1732 6.62781 18.1996 6.76063 18.1996 6.89476C18.1996 7.02889 18.1732 7.1617 18.1219 7.28562C18.0705 7.40954 17.9953 7.52214 17.9005 7.61698L13.4443 12.0586L17.7777 16.4064Z"
				fill="black"
			/>
		</svg>
	)
}

export default CloseIcon
