import { StyleSheet, css } from 'aphrodite'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../constants'

const INITIAL_REGISTER_FORM_DATA = {
  userName: '',
  password: '',
}

function RegisterForm() {
  const [userRoles, setUserRoles] = useState([])
  const [userDetails, setUserDetails] = useState(INITIAL_REGISTER_FORM_DATA)
  const [currentUserRole, setCurrentUserRole] = useState('')

  function getUserTypeList() {
    axios
      .get(BASE_URL + '/auth/userRoles')
      .then((response) => {
        setUserRoles(Object.values(response.data))
      })
      .catch(function (error) {
        if (error.response.data) {
          let errorMessages = error.response.data
          alert(
            `Status : ${errorMessages.status} \nStatus Code : ${errorMessages.statusCode} \nStatus Message : ${errorMessages.statusMessage}`
          )
        } else if (error.request.data) {
          console.log('error.request : ', error.request)
        } else {
          alert(`Error Code : ${error.code} \nError Message : ${error.message}`)
        }
      })
  }

  const formData = CreateUser(userDetails, currentUserRole)

  async function HandleRegisterSubmitAsync(e) {
    e.preventDefault()
    return await axios({
      method: 'POST',
      url: BASE_URL + '/auth/register',
      headers: {
        'Content-Type': 'application/json',
      },
      data: formData,
    })
      .then((res) => {
        console.log('res : ', res)
        alert(
          `Status : ${res.data.success} \nStatus Message : ${res.data.message}`
        )
      })
      .catch(function (error) {
        console.log('error : ', error)
        if (error.response.data) {
          let errorMessages = error.response.data
          alert(
            `Status : ${errorMessages.success} \nStatus Message : ${errorMessages.message}`
          )
        } else if (error.request.data) {
          console.log('error.request : ', error.request)
        } else {
          alert(`Error Code : ${error.code} \nError Message : ${error.message}`)
        }
      })
  }

  function CreateUser(userDetails, currentUserRole) {
    return {
      userName: userDetails.userName[0],
      password: userDetails.password[0],
      roles: currentUserRole,
    }
  }

  function HandleInput(input) {
    setUserDetails((prevState) => ({
      ...prevState,
      [input.target.name]: [input.target.value],
    }))
  }

  function HandleDropdown(e) {
    setCurrentUserRole(e.target.value)
  }

  useEffect(() => {
    getUserTypeList()
  }, [currentUserRole])

  return (
    <>
      <div className={css(styles.RegisterFormContainer)}>
        <h1>Register Page</h1>
        <form onSubmit={HandleRegisterSubmitAsync}>
          <div className={css(styles.FormElement)}>
            <label type="name">username : </label>
            <input
              type="text"
              id="text"
              name="userName"
              onChange={HandleInput}
            ></input>
          </div>

          <div className={css(styles.FormElement)}>
            <label type="password">password : </label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={HandleInput}
            ></input>
          </div>

          <div className={css(styles.FormElement)}>
            <label type="text">user type : </label>
            <select value={currentUserRole} onChange={HandleDropdown}>
              {userRoles?.map((role) => {
                return (
                  <option value={role} name="roles" key={role}>
                    {role}
                  </option>
                )
              })}
            </select>
          </div>
        </form>

        <input
          className={css(styles.FormElement)}
          type="submit"
          value="SUBMIT"
        ></input>

        <button onClick={HandleRegisterSubmitAsync}>CLICK</button>

        <div className={css(styles.FormElement)}>
          Already have an account ?
          <Link to={'/auth/login'}>
            <button type="button" className={css(styles.FormElement)}>
              login
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default RegisterForm

const styles = StyleSheet.create({
  RegisterFormContainer: {
    border: '1px solid black',
    borderRadius: '10px',
    width: '30%',
    height: '50%',
    margin: '100px auto',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'auto',
  },

  FormElement: {
    margin: '5px',
    justifyContent: 'center',
    pointerEvents: 'auto',
    alignItems: 'center',
  },
})
