import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../constants";
import { getUserData } from "../getUserData";

export const userUserInfo = () => {
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    getUserData().then((user) => {
      setUserInfo(user);
    });
  }, []);
  return userInfo;
};

export const useClientInfo = (clientID) => {
  const [clientInfo, setClientInfo] = useState({});
  useEffect(() => {
    axios
      .get(`${BASE_URL}/auth/client?_id=${clientID}`)
      .then((res) => {
        if (res.data && res.data[0]) {
          setClientInfo(res.data[0]);
        }
      })
      .catch(console.log);
  }, [clientID]);
  return clientInfo
};
