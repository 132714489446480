import { css, StyleSheet } from 'aphrodite'

export const DropDown = ({
	labelText,
	customLabelStyles,
	customInputStyles,
	required,
	items,
	value,
	onChange,
}) => {
	return (
		<label className={css(styles.label, customLabelStyles)}>
			{labelText && <p> {labelText} </p> }
			<select
				value={value}
				className={css(styles.bar, customInputStyles)}
				onChange={(e) => onChange(e.target.value)}
			>
				{items.map((item) => {
					return <option value={item}>{item}</option>
				})}
			</select>
		</label>
	)
}

const styles = StyleSheet.create({
	bar: {
		border: 0,
		outline: 0,
		// background: 'rgba(217, 217, 217, 1)',
		padding: '.5rem',
		borderRadius: '.5rem',
		boxShadow: '1px 1px 3px 0px #00000026',
	},
	label: {
		fontWeight: 600,
	},
})
