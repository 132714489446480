import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useState } from 'react'
import uuid from 'react-uuid'
import { getUserData } from '../../auth/getUserData'
import { useClientInfo, userUserInfo } from '../../auth/hooks/useUserInfo'
import { BASE_URL } from '../../constants'
import { useData, useModal, useStatusMessage } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import { DropDown } from '../../uiElements/DropDown'
import TextInput from '../../uiElements/TextInput'

const ResponseModal = ({ otp }) => {
	return (
		<div className={css(styles.otpContainer)}>
			{otp && <sub>OTP GENERATED!ENTER THIS CODE INTO THE DEVICE TO LOGIN</sub>}
			<h1 style={{ color: 'red', fontSize: '3rem' }}>{otp || 'Created User'}</h1>
			{otp && <sub>
				Please note this down and keep it safely, expires in 48 hours
			</sub>}
		</div>
	)
}
export const AddUsers = () => {
	const { setActiveUserProfileSubPage } = useData()
	const [userName, setUserName] = useState('')
	const [password, setPassword] = useState('')
	const user = userUserInfo()
	const client = useClientInfo(user.clientID)
	const [email, setEmail] = useState('')
	const [role, setRole] = useState('trainee')
	const [pincode, setPincode] = useState('')
	const { setComponent } = useModal()
	const {setMessage} = useStatusMessage()
	const onSubmit = async (e) => {
		e.preventDefault()
		try {
			const { clientID } = await getUserData()
			console.log('asd', clientID)
			const newEntry = {
				clientID,
				userName,
				...(email?{email}:{}),
				roles: role,
				pincode,
				password: password || uuid(),
			}
			const response = await axios.post(
				`${BASE_URL}/auth/user/register`,
				newEntry
			)
			setMessage(`${role[0].toUpperCase()+role.slice(1,role.length)} created...`)
			console.log('response', response)
			const otp = response.data.otp
			setComponent(<ResponseModal otp={otp} />)
		} catch (err) {
			setMessage(`User already exists...`)
			console.log(err)
		}
	}
	// console.log(userName, role, client)
	return (
		<div className={css(styles.page)}>
			<BreadCrumb
				items={[
					{
						name: 'USERS',
						clickHandler: () => setActiveUserProfileSubPage('main'),
					},
					{ name: 'ADD USER' },
				]}
			/>
			<div className={css(styles.container)}>
				<div className={css(styles.add)}>
					<form onSubmit={onSubmit}>
						{/* <h3>Create User</h3> */}
						<TextInput
							labelText="Username"
							customInputStyles={styles.input}
							value={userName}
							required
							onChange={(e) => setUserName(e.target.value)}
						/>
						{client.clientName==='Shell' && <TextInput
							labelText="Email"
							customInputStyles={styles.input}
							value={email}
							required
							onChange={(e) => setEmail(e.target.value)}
						/>}
						<TextInput
							labelText={client.loginType==='device'?"Pincode":"Password"}
							customInputStyles={styles.input}
							value={client.loginType==='device'? pincode: password}
							required
							onChange={(e) =>client.loginType==='device'? setPincode(e.target.value): setPassword(e.target.value)}
						/>
						<DropDown
							labelText="USER ROLE"
							value={role}
							onChange={(val) => setRole(val)}
							customInputStyles={styles.dropdown}
							items={['trainee', 'trainer']}
						/>
						<Button customStyles={styles.button}>
							SAVE AND INVITE
						</Button>
					</form>
				</div>
			</div>
		</div>
	)
}

const styles = StyleSheet.create({
	page: {
		// paddingLeft: '4rem',
	},
	container: {
		borderRadius: '15px',
		boxShadow: '1px 1px 3px 0px #00000026',
		width: '24rem',
	},
	input: {
		width: '20rem',
		boxShadow: '1px 1px 3px 0px #00000026',
		background: 'white',
	},
	dropdown: {
		width: '20.7rem',
	},
	button: {
		marginTop: '2rem',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	add: {
		display: 'flex',
		gap: '2rem',
	},
	otpContainer: {
		padding: '3rem',
		borderRadius: '15px',
		border: 'black solid 5px',
		textAlign: 'center',
		background: 'white',
	},
})
