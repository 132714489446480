import axios from 'axios'
import { useEffect, useState } from 'react'
import { BASE_URL } from '../../constants'

export const useEvaluations = (query = '') => {
  const [evals, set] = useState([])
  useEffect(() => {
    axios
      .get(BASE_URL + `/api/v1/evaluation?${query}`)
      .then(({ data }) => {
        console.log('inside eval', data)
        set(data)
      })
      .catch(console.log)
  }, [query])
  return evals
}
