import React, { useEffect, useState } from 'react'
import BreadCrumb from './BreadCrumb'
import { css, StyleSheet } from 'aphrodite'
import Button from './Button'
import { useData } from '../store'
import { useEvaluations } from '../auth/hooks/useEvaluation'
import { userUserInfo } from '../auth/hooks/useUserInfo'

function EvaluationReport(props) {
  const { setActiveEvaluationPage } = useData()
  // const [evals] = useEvaluations(`userId=${userId}`)
  const user = userUserInfo()

  const evals = useEvaluations(
    `userId=${props.userId}&module=${props.moduleName}`
  )
  // useEffect(() => {
  //   console.log(evals)
  // }, [evals])

  // const data = [
  //   {
  //     id: 1,
  //     que: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.?Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.?',
  //     status: 'CORRECT',
  //     updatedAt: '2022-08-24T07:18:46.434Z',

  //     parsedEvaluationData.options: [
  //       {
  //         title: 'choice1',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice2',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice3',
  //         selectStatus: true,
  //       },
  //       {
  //         title: 'choice4',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice5',
  //         selectStatus: false,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     que: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.?',
  //     updatedAt: '2022-08-24T07:19:46.434Z',
  //     parsedEvaluationData.options: [
  //       {
  //         title: 'choice1',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice2',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice3',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice4',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice5',
  //         selectStatus: true,
  //       },
  //       {
  //         title: 'choice6',
  //         selectStatus: false,
  //       },
  //     ],
  //     status: 'WRONG',
  //   },
  //   {
  //     id: 3,
  //     que: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.?',
  //     updatedAt: '2022-08-24T07:20:46.434Z',
  //     parsedEvaluationData.options: [
  //       {
  //         title: 'choice1',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice2',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice3',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice4',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice5',
  //         selectStatus: true,
  //       },
  //       {
  //         title: 'choice6',
  //         selectStatus: false,
  //       },
  //     ],
  //     status: 'CORRECT',
  //   },
  //   {
  //     id: 4,
  //     que: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.?',
  //     updatedAt: '2022-08-24T07:11:46.434Z',
  //     parsedEvaluationData.options: [
  //       {
  //         title: 'choice1',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice2',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice3',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice4',
  //         selectStatus: true,
  //       },
  //     ],
  //     status: 'WRONG',
  //   },
  //   {
  //     id: 5,
  //     que: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.?',
  //     updatedAt: '2022-08-24T07:11:46.434Z',
  //     parsedEvaluationData.options: [
  //       {
  //         title: 'choice1',
  //         selectStatus: false,
  //       },
  //       {
  //         title: 'choice2',
  //         selectStatus: true,
  //       },
  //       {
  //         title: 'choice3',
  //         selectStatus: false,
  //       },
  //     ],
  //     status: 'CORRECT',
  //   },
  // ]

  return (
    <div className={css(styles.ListContainer)}>
      <div className={css(styles.header)}>
        <h2
          style={{ marginLeft: '2vh', fontSize: '3.2vh', fontWeight: 'bold' }}
        >
          REPORT
        </h2>
        <h2
          style={{
            marginRight: '2vh',
            fontSize: '3.2vh',
            fontWeight: 'bold',
          }}
        >
          Total Score: {evals.reduce((a, b) => a + Number(b.score), 0)}
        </h2>
      </div>

      <div className={css(styles.body)}>
        <>
          {evals != null &&
            evals.map((evaluation, idx) => {
              let parsedEvaluationData
              try {
                parsedEvaluationData = JSON.parse(evaluation.data)
                console.log(parsedEvaluationData)
              } catch (err) {
                console.log(err)
              }
              if (!parsedEvaluationData) return null
              return (
                <div className={css(styles.contentContainer)}>
                  <div className={css(styles.content)}>
                    <div className={css(styles.que)}>
                      {parsedEvaluationData.question}
                    </div>

                    <div
                      className={css(
                        styles.remark,
                        evaluation.score != '0'
                          ? styles.correctStatus
                          : styles.wrongStatus
                      )}
                    >
                      {evaluation.score != '0' ? 'CORRECT' : 'WRONG'}
                    </div>
                  </div>
                  <div className={css(styles.updateStatus)}>
                    {evaluation.updatedAt != null
                      ? evaluation.updatedAt.replace('T', ' ').split('.')[0]
                      : item.updatedAt}
                  </div>
                  <div className={css(styles.choiceContent)}>
                    {parsedEvaluationData.options != null &&
                      parsedEvaluationData.options.map((choice, choiceIdx) => {
                        // console.log(choiceIdx, evaluation)
                        return (
                          <div
                            className={css(
                              styles.choices,
                              choiceIdx === parsedEvaluationData.response
                                ? evaluation.score == '0'
                                  ? styles.selectedWrong
                                  : styles.selectedTrue
                                : styles.notSelected
                              // choice.selectStatus === true
                              //   ? item.status === 'CORRECT'
                              //     ? styles.selectedTrue
                              //     : styles.selectedWrong
                              //   : styles.notSelected
                            )}
                          >
                            {choice}
                          </div>
                        )
                      })}
                  </div>
                </div>
              )
            })}
        </>
      </div>
    </div>
  )
}

export default EvaluationReport

const styles = StyleSheet.create({
  updateStatus: {
    display: 'flex',
    justifyContent: 'flex-end',
    // background: 'red',
    // width: '100%',
    marginTop: '-2vh',
    paddingRight: '2vh',
    paddingBottom: '2vh',
  },
  selectedTrue: {
    background: 'green',
    color: 'white',
  },
  selectedWrong: {
    background: 'red',
    color: 'white',
  },
  notSelected: {
    background: 'white',
    color: 'black',
  },
  remark: {
    fontWeight: 'bold',
  },
  correctStatus: {
    color: 'green',
  },
  wrongStatus: {
    color: 'red',
  },
  ListContainer: {
    height: '75vh',
    border: '0.6vh solid rgb(251, 206, 7)',
    backgroundColor: 'rgb(251, 206, 7)',
    borderRadius: '2vh',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '10.4vh',
    background: 'rgb(251, 206, 7)',
    marginTop: '-1px',
    padding: '1rem 1rem 0px',
    borderTopLeftRadius: '2vh',
    borderTopRightRadius: '2vh',
  },
  body: {
    fontSize: '2vh',
    padding: '2vh',
    height: '78%',
    overflowY: 'auto',
    borderBottomLeftRadius: '2vh',
    borderBottomRightRadius: '2vh',
    background: 'rgb(255,255,255)',
    maxHeight: '100%',
    '::-webkit-scrollbar': {
      width: '.7rem',
      background: 'rgba(140, 140, 140, 1)',
      borderRadius: 5,
      position: 'absolute',
      display: 'block',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(35, 34, 34, 1)',
      borderRadius: 5,
    },
  },
  contentContainer: {
    float: 'left',
    width: '47.5%',
    border: '0.3vh solid #DBE1E8',
    // height: '50%',
    marginTop: '1%',
    borderRadius: '1vh',
    maxHeight: '35vh',
    height: '35vh',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '.7rem',
      background: 'rgba(140, 140, 140, 1)',
      borderRadius: 5,
      position: 'absolute',
      display: 'block',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(35, 34, 34, 1)',
      borderRadius: 5,
    },
    ':nth-child(even)': {
      marginLeft: '3%',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2vh',
  },
  choiceContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1vh',
    // height: '35vh',
    // marginLeft: '2vh',
    // marginRight: '2vh',
    // marginTop: '1vh',
  },
  choices: {
    // marginLeft: '2vh',
    // marginRight: '2vh',
    margin: '0.8vh',
    padding: '1.8vh',
    borderRadius: '2vh',
    border: '0.3vh solid #DBE1E8',
  },
  que: {
    width: '80%',
  },
  list01: {
    height: '90%',
    maxHeight: '100%',
    overflowY: 'auto',
    borderTop: '2px solid rgba(219, 225, 232, 1)',
    borderRight: '2px solid rgba(219, 225, 232, 1)',
    marginLeft: '-1rem',
    marginTop: '1rem',
    '::-webkit-scrollbar': {
      width: '.7rem',
      background: 'rgba(140, 140, 140, 1)',
      borderRadius: 5,
      position: 'absolute',
      display: 'block',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(35, 34, 34, 1)',
      borderRadius: 5,
    },
  },
  container: {
    // position: 'absolute',
    // // paddingBottom: '2rem',
    // top: '10%',
    // bottom: '3%',
    overflow: 'hidden',
    // left: '5%',
    // right: '5%',
    // background: 'red',
    display: 'flex',
    justifyContent: 'center',
    height: '80vh',
  },
  flexBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  list: {
    width: '100%',
    borderRadius: 10,
    background: 'rgba(251, 206, 7, 1)',
    padding: 0,
    border: '3px solid rgba(251, 206, 7, 1)',
  },
  listHeader: {
    padding: '1rem 1rem 0px',
  },
  listBody: {
    background: '#fff',
    paddingLeft: '1rem',
  },
  listSingleItem: {
    border: '2px solid rgba(219, 225, 232, 1)',
    margin: '1rem .5rem',
    borderRadius: 20,
    ':hover': {
      // background: 'red',
      border: '2px solid red',
      color: '#fff',
      ':nth-child(n) button': {
        color: '#fff',
      },
      ':nth-child(n) svg': {
        fill: '#fff',
        filter: 'invert(100%)',
      },
    },
  },
  button: {
    background: 'rgba(251, 206, 7, 1)',
    color: '#6D6D6D',
    fontSize: '2vh',
  },
})
